
import '@/assets/css/iframeStyle.scss';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import OrderDetail from "./component/OrderDetail.vue";
import Evaluate from "./component/Evaluate.vue";
import InvoiceInfo from "./component/InvoiceInfo.vue";
import Proof from "./component/Proof.vue";
import PaySuccess from "./component/PaySuccess.vue";
import OrderItem from "./component/OrderItem.vue";
import OrderItemAgent from "./component/OrderItem_agent.vue";
import DriveAuthorizeDrawer from "./component/DriveAuthorizeDrawer.vue";
import { useMessage } from '@/utils/message'; // 方便弹出吐司
import { moneyFormat } from '@/utils/utils';

let preActivePage = 1;
@Component({
    name: "MyOrder",
    components: {
        OrderDetail,
        InvoiceInfo,
        Proof,
        PaySuccess,
        Evaluate,
        OrderItem,
        OrderItemAgent,
        DriveAuthorizeDrawer,
    },
})
export default class MyOrder extends Vue {
    private baseUrlIfram = process.env.VUE_APP_URL;
    private activePage: number = 1; // 1、主页面；2、订单详情；3、商品评价；4、支付页iframe；5、支付成功页面；
    private pageNo: number = 1;
    private pageSize: number = 10;
    private total: number = 0;
    private orderStatus: any = null;    // 0:待支付；3:交易完成；null:全部订单；
    private orderType: string = ''; // 1、待支付；2、交易完成；3、全部订单；
    private allUploadFalg: boolean = false;
    private allPayFalg: boolean = false;
    private companyList: any = [];  // 代购企业列表
    private bankList: any = []; // 银行列表
    private orderCount: object = {
        allNum: 0,      // 所有订单数
        receivedNum: 0, // 已完成订单数
        unpayNum: 0,    // 未完成订单数
    };
    // OrderDetail
    private detailContent: any = {};
    // end
    // InvoiceInfo
    private infoFlag: boolean = false;
    private invoiceOrderIdList: any = [];
    private invoiceInfo: object = {};
    // end
    // Proof
    private proofSt: number = 1;    // 1： 查看凭证；2：上传凭证
    private proofFlag: boolean = false;
    // end
    // PaymentProof
    private paymentOrderIds: string = '';
    // end
    // DriveAuthorizeDrawer
    private drawerDriveAuthorizeFlag: boolean = false;
    // end
    private orderData: any = {
        buyRole: 0, // 0: 我的订单；1: 代购订单；
        orderNo: '',
        payUserId: '',
        // startTime: '',
        // endTime: '',
    };
    private paySuccessId: any = null;   // 支付成功订单id
    private checkAll: boolean = false;
    private isIndeterminate: boolean = false;
    private checkOrderList: any = [];   // 当前已选订单id列表
    private orderIdList: any = [];  // 当前可选择订单id列表
    private orderDataList: any = [];
    private showEmpty: number = 0;
    private isFixed: boolean = false;

    @Watch('activePage')
    private activePageChange(newVal: number, oldVal: number) {
        preActivePage = oldVal;
    }
    private scrollToTop() {
        if (document.querySelector('#myOrder')) {
            const scrollEl: any = document.querySelector('#myOrder');
            scrollEl.scrollTo(0, 0);
        }
    }

    private handleSizeChange(val: number) {
        this.pageNo = 1;
        this.pageSize = val;
        this.scrollToTop();
        this.getOrderList();
    }
    private handleCurrentChange(val: number) {
        this.pageNo = val;
        this.scrollToTop();
        this.getOrderList();
    }
    // 金额格式化
    private moneyFormat(value: number) {
        return moneyFormat(value, 2);
    }
    private mounted() {
        this.$nextTick(() => {
            if (document.querySelector('#myOrder')) {
                const scrollEl: any = document.querySelector('#myOrder');
                scrollEl.addEventListener("scroll", this.scrollChange, false);
            }
        });
    }
    private destroyed() {
        if (document.querySelector('#myOrder')) {
            const scrollEl: any = document.querySelector('#myOrder');
            scrollEl.removeEventListener("scroll", this.scrollChange, false);
        }
    }
    private scrollChange(e: any) {
        const scrollTop = e.target.scrollTop;
        this.isFixed = scrollTop > 160;
    }
    private async created() {
        const orderStatus = this.$route.query.orderStatus;  // 0: 待支付；6: 交易完成；all: 全部订单；
        if (orderStatus === '0') {
            this.orderStatus = 0;
        } else if (orderStatus === '6') {
            this.orderStatus = 3;
        } else {
            this.orderStatus = null;
        }
        await this.init();
        await this.getAgentCompany();
        await this.getBankList();
        await this.getInvoice();
    }
    private async init() {
        await this.getOrderList();
        await this.getOrderCount();
    }
    // 监听订单状态变化
    private tabClick(value: any) {
        // if (value.name !== this.orderType) {
        this.orderStatus = value.name === '1' ? 0 : value.name === '2' ? 3 : null;
        this.orderType = value.name;
        this.isIndeterminate = false;
        this.checkAll = false;
        this.checkOrderList = [];
        this.orderData.orderNo = "";
        this.pageNo = 1;
        this.init();
        // }
    }
    // 获取订单列表
    private getOrderList() {
        const formData: any = {
            pageNo: this.pageNo,
            pageSize: this.pageSize,
            ...this.orderData,
        };
        if (this.orderData.buyRole === 0) {
            formData.orderStatus = this.orderStatus;
        }
        this.$httpService.getData(formData, '/apiProxy/api/frontend/orders')
            .then((res: any) => {
                this.orderDataList = res.list || [];
                this.total = res.total;
                const resultArr: any = [];
                const mapKey = 'id';
                this.orderDataList.map((item: any) => {
                    if (item[mapKey]) {
                        if (this.orderType === '2' && item.orderStatus === 3 && item.money
                            > 0.001 && item.invoiceStatus === 0) {  // 交易完成；并且订单金额大于 0.001的待开票订单
                            resultArr.push(item[mapKey]);
                        }
                        if (this.orderType === '1' && item.orderStatus === 0    // 待支付
                            && ((new Date(item.autoPayTimeOutTime).getTime()
                                > new Date().getTime() && item.payStyle !== 1) // 订单未超时
                            && !item.orderFirstOpenIdentity   // 初次开通服务
                            && !item.orderGoodsGroups    // 合并订单
                            && (!item.orderCreateType || item.orderCreateType !== 3))) {   // 试用转正订单
                            resultArr.push(item[mapKey]);
                        }
                    }
                });
                this.orderIdList = resultArr;
                if (this.orderDataList.length <= 0) {
                    this.showEmpty = this.orderData.orderNo || this.orderData.payUserId ? 2 : 1;
                } else {
                    this.showEmpty = 0;
                }
            });
    }
    // 获取订单状态计数
    private async getOrderCount() {
        await this.$httpService.getData({}, '/apiProxy/api/frontend/orders/count')
            .then((res: any) => {
                this.orderCount = Object.assign({}, res);
            });
        if (this.orderStatus === 0) {
            this.orderType = '1';
        } else if (this.orderStatus === 3) {
            this.orderType = '2';
        } else {
            this.orderType = '3';
        }
    }
    // 获取代购企业
    private getAgentCompany() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/company/info/purchasing-agent/customers')
            .then((res: any) => {
                this.companyList = res;
            });
    }
    // 获取银行列表
    private getBankList() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/company/info/offline-bank')
            .then((res: any) => {
                this.bankList = res;
            });
    }
    // 获取开票信息
    private getInvoice() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/company/info/invoice-info')
            .then((res: any) => {
                this.invoiceInfo = res;
            });
    }
    private searchHandle(formName: any) {
        // const elForm = this.$refs[formName]! as ElForm;
        this.pageNo = 1;
        this.init();
    }
    private resetHandle(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        elForm.resetFields();
    }
    // 返回上一页
    private returnHandle(num: number) {
        if (this.activePage === 3) {
            this.activePage = preActivePage;
        } else {
            this.activePage = 1;
        }
    }
    private paySucToOrderList(val: string) {
        this.activePage = 1;
        this.tabClick({name: val});
    }
    private paySucToDetail(row: any) {
        this.gotoDeatil(row);
        this.init();
    }
    // 修改订单类型 1：我的订单；2：代购订单
    private changeTitleType(value: number) {
        if (this.orderData.buyRole !== value) {
            this.orderData.orderNo = '';
            this.orderData.payUserId = '';
            this.orderData.buyRole = value;
            this.orderDataList = [];
            this.searchHandle('refOrder');
        }
    }
    // 获取订单详情
    private getOrderDetail(orderId: number) {
        this.$httpService.getData({}, `/apiProxy/api/frontend/orders/${orderId}`)
            .then((res: any) => {
                this.detailContent = res || {};
            });
    }
    // 查看详情
    private gotoDeatil(row: any) {
        this.getOrderDetail(row.id);
        this.activePage = 2;
    }
    // 立即评价
    private gotoEvaluate(row: any) {
        this.activePage = 3;
        this.detailContent = Object.assign({}, row);
    }
    private evaluateSubmitMsg(num: number) {
        this.activePage = preActivePage;
        this.init();
        if (this.activePage === 2) {
            this.getOrderDetail(this.detailContent.id);
        }
    }
    // 批量申请开票
    private batchAskforInvoice() {
        if (this.checkOrderList.length <= 0) {
            useMessage(3, '请先选择需要索要的订单');
            return;
        }
        this.invoiceOrderIdList = this.checkOrderList;
        this.infoFlag = true;
    }
    // 申请开票
    private askforInvoice(row: any) {
        this.invoiceOrderIdList = [row.id];
        this.infoFlag = true;
    }
    private invoiceCloseMsg(num: number) {
        this.infoFlag = false;
        if (num === 3) {
            this.init();
            if (this.activePage === 2) {
                this.getOrderDetail(this.detailContent.id);
            }
        }
    }
    // 查看凭证
    private proofClick(row: any, proofSt: number) {
        this.proofFlag = true;
        this.proofSt = proofSt;
        this.detailContent = Object.assign({}, row);
    }
    private propCloseMsg(num: number) {
        this.proofFlag = false;
        if ( num === 3) {
            this.init();
            if (this.activePage === 2) {
                this.getOrderDetail(this.detailContent.id);
            }
        }
    }
    private handleCheckAllChange(val: any) {
        this.checkOrderList = val ? this.orderIdList : [];
        this.isIndeterminate = false;
        // if (this.orderType === '1' && val) {
        //     this.allUploadFalg = true;
        //     // this.allPayFalg = true;
        // } else {
        //     this.allUploadFalg = false;
        //     // this.allPayFalg = false;
        // }
    }
    private handleCheckedOrdersChange(val: any) {
        const checkedCount = val.length;
        this.checkAll = checkedCount === this.orderIdList.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.orderIdList.length;
        // if (this.orderType === '1' && val.length > 0) {
        //     this.allUploadFalg = true;
        //     // this.allPayFalg = true;
        // } else {
        //     this.allUploadFalg = false;
        //     // this.allPayFalg = false;
        // }
    }
    private handleCheckOrdersPay(val: any) {
        this.checkOrderList = val ? this.orderIdList : [];
        this.checkAll = val;
    }
    // 立即支付
    private allUploadProof(row: any) {
        this.$httpService.postData({}, '/apiProxy/api/frontend/order/pay/free-pay/' + row.id)
            .then((res: any) => {
                const zeroOrder = res.zeroOrder;
                const success = res.success;
                const firstOrder = res.firstOrder;
                if (!success) {
                    useMessage(3, res.message);
                } else {
                    if (zeroOrder === "true") {
                        this.detailContent = row;
                        this.paySuccessId = res.groupsOrderId || row.id;
                        this.activePage = 5;
                    } else {
                        this.paymentOrderIds = res.groupsOrderId || row.id;
                        this.activePage = 4;
                    }
                }
            });
    }
    // 合并支付
    private askForCheckList() {
        if (this.checkOrderList.length <= 0) {
            useMessage(3, '请先选择需要索要的订单');
            return;
        }
        this.paymentOrderIds = this.checkOrderList.join(',');
        this.$httpService.postData({orderIds: this.checkOrderList}, '/apiProxy/api/frontend/order/pay/merge-verify')
            .then((res: any) => {
                const success = res.success;
                const residueOrderQuantity = res.residueOrderQuantity;
                if (!success) {
                    useMessage(3, res.message);
                } else if (success && residueOrderQuantity === 0) {
                    if (this.checkOrderList.length === 1) {
                        const data = this.orderDataList.find((i: any) => i.id === this.paymentOrderIds);
                        if (data) {
                            this.detailContent = data;
                            this.paySuccessId = this.paymentOrderIds;
                        } else {
                            this.paySuccessId = null;
                        }
                    }
                    this.activePage = 5;
                } else if (success && residueOrderQuantity > 0) {
                    this.paymentOrderIds = res.residueOrderId;
                    this.activePage = 4;
                }
            });
    }
    //  取消订单
    private cancelOrder(row: any) {
        this.$confirm(`确定要取消订单：${row.orderNo} 吗？`, '取消订单', {
            confirmButtonText: '确定',
            cancelButtonText: '暂不取消',
            center: false,
            beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                    instance.confirmButtonLoading = true;
                    this.$httpService.postData({}, '/apiProxy/api/frontend/orders/cancle/' + row.id)
                        .then((res: any) => {
                            done();
                            this.$message({
                                type: 'success',
                                message: '已成功取消订单！',
                            });
                            this.init();
                            if (this.activePage === 2) {
                                this.getOrderDetail(this.detailContent.id);
                            }
                            instance.confirmButtonLoading = false;
                        }).catch(() => {
                            instance.confirmButtonLoading = false;
                        });
                } else {
                    done();
                }
            },
        });
    }
    // 前往云商城
    private goMall() {
        // window.location.href = this.baseUrlIfram + '/mall/index.htm';
        this.$router.push('/mall');
    }
    // 前往驱动与授权许可下载
    private gotoDriveAuthorize(row: any) {
        this.detailContent = Object.assign({}, row);
        this.drawerDriveAuthorizeFlag = true;
    }
    private handleDriveAuthorizeClose() {
        this.drawerDriveAuthorizeFlag = false;
    }
    private downloadContract(row: any) {
        this.$httpService.getData({}, `/apiProxy/api/frontend/orders/${row.id}/order/contract`)
        .then((res: any) => {
            this.$message.success('文件下载中，请稍等');
            this.downloadClick(row, res);
        });
    }
    // 下载合同
    private async downloadClick(row: any, fileUrl: any) {
        const contractUrl = fileUrl;
        const baseUrlIframe = process.env.VUE_APP_URL;
        const response = await fetch(baseUrlIframe + contractUrl);
        const blob = await response.blob();
        let contractName = `订单编号${row.orderNo}-合同`;
        if (contractUrl.indexOf('attname=') > -1) {
            const fileFullName = contractUrl.split('attname=')[1];
            contractName = fileFullName.substring(0, fileFullName.lastIndexOf('.'));
        }
        this.triggerADownload(URL.createObjectURL(blob), contractName);
    }
    private triggerADownload(blob: any, contractName: any) {
        const a = document.createElement('a');
        const fileName = contractName;
        document.body.appendChild(a);
        a.href = blob;
        a.download = fileName;
        a.click();
        document.body.removeChild(a);
    }
}
