
import '@/assets/css/iframeStyle.scss';
import { Component, Vue } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";

@Component({
    name: "RedirectIframe",
    components: {
        ContentTitle,
    },
})
export default class RedirectIframe extends Vue {
    private contentTitle: string = "订单确认页";
    private baseUrlIfram = process.env.VUE_APP_URL;
    private urlParam = this.$route.query.url + '?' + this.$route.query.data;
    private loading: boolean = true;

    private created() {
        this.$nextTick( () => {
            // 注意： 如果本身iframe嵌套的盒子有延迟 则下面判断最好也加延迟
            const iframe: any = document.querySelector('#redirect_iframe');
            // 处理兼容行问题
            if (iframe.attachEvent) {
                iframe.attachEvent('onload', () => {
                    this.loading = false;
                });
            } else {
                iframe.onload = () => {
                    this.loading = false;
                };
            }
        });
    }
}
