
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { useMessage } from '@/utils/message'; // 方便弹出吐司
@Component({
  name: 'Proof',
})
export default class Proof extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    }) private exitFlag!: boolean; // 接收父组件传过来的值
    @Prop({
        type: Number,
        default: 1, // 1、查看凭证；2、上传凭证
    }) private status!: number; // 接收父组件传过来的值
    @Prop({
        type: Object,
        default: {},
    }) private content!: any; // 接收父组件传过来的值
    @Prop({
        type: Function,
        default: () => '',
    }) private moneyFormat!: any;

    private baseUrl = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private showDialog: boolean = false; // 弹窗显示与关闭
    private caption: string = "查看转账凭证";
    private proofSt: number = 1;    // 1：查看凭证；2：上传凭证
    private rowData: any = {};
    private submitLoading = false;
    private imageList: any = [];
    private fileList: any = [];
    private groupsOrderIdList: any = [];
    private voucherInfo: any = {};

    @Watch('exitFlag') // 监听父组件传过来的值
    private exitFlagFun(value: boolean) {
        this.showDialog = value;
        if (value) {
            this.proofSt = this.status;
            // if (this.status === 1) {
            this.getVoucher();
            // }
        } else {
            this.rowData = {};
            this.fileList = [];
            this.imageList = [];
        }
    }

    @Watch('content') // 监听父组件传过来的值
    private contentFun(value: object) {
        this.rowData = JSON.parse(JSON.stringify(value));
    }

    // 给父组件传值
    @Emit('closeMsg')
    private closeTodo(num: number): number {
        return num;
    }

    // 获取支付凭证
    private getVoucher() {
        this.$httpService.postData({}, `/apiProxy/api/frontend/orders/${this.content.id}/offline-payment/voucher`)
            .then((res: any) => {
                this.groupsOrderIdList = res.groupsOrderIdList || this.content.id;
                this.voucherInfo = {
                    voucherLog: res.voucherLog,
                    voucherStatus: res.voucherStatus,
                };
                if (res.imageList.length) {
                    res.imageList.map((item: any) => {
                        this.fileList.push(item.image);
                        this.imageList.push(this.baseUrl + item.image);
                    });
                }
            });
    }
    //  确定按钮
    private closeDialog(num: number, submitName?: string) {
        // 提交凭证
        this.submitLoading = true;
        this.$httpService.postData({
            orderIds: this.groupsOrderIdList,
            images: this.fileList,
        }, '/apiProxy/api/frontend/orders/transfer-voucher/upload')
        .then((res: any) => {
            this.showDialog = false;
            this.submitLoading = false;
            this.closeTodo(num); // 给父组件传值
            this.$message({
                message: '提交凭证成功！',
                type: 'success',
            });
        }).catch(() => {
            this.submitLoading = false;
        });
    }
    // 重新上传
    private reUpload() {
        this.proofSt = 2;
    }
    // 右上角的关闭按钮
    private beforeclose() {
        this.showDialog = false;
        this.closeTodo(1); // 给父组件传值
    }
    // 删除图片
    private removeImg(index: number) {
        this.fileList.splice(index, 1);
        this.imageList.splice(index, 1);
    }
    private clearInput() {
        const input: any = this.$refs.uploadInput;
        input.value = "";
    }
    // 上传图片
    private chooseFile(event: any) {
        const fileData = event.target.files[0];
        const typeList = ['image/png', 'image/jpeg', 'image/jpg'];
        const fileSize = fileData.size / 1024 / 1024;
        if (this.fileList.length > 2) {
            useMessage(3, '最多选择3张图片');
            this.clearInput();
        } else if (!typeList.includes(fileData.type)) {
            useMessage(3, '请上传jpg,jpeg,png文件格式');
            this.clearInput();
        } else if (fileSize > 2) {
            useMessage(3, '文件大小不能超过2M');
            this.clearInput();
        } else {
            // this.fileList.push(URL.createObjectURL(fileData));
            const param = new FormData();
            param.append('file', fileData);
            this.$httpService.postData(param, '/apiProxy/api/frontend/file/upload_image', {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            }).then((res: any) => {
                if (this.fileList.includes(res.path)) {
                    useMessage(3, '该图片已上传');
                } else {
                    this.fileList.push(res.path);
                    this.imageList.push(this.baseUrl + res.path);
                }
                this.clearInput();
            });
        }
    }
}
