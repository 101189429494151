
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
@Component({
  name: 'OrderItem',
})
export default class OrderItem extends Vue {
    @Prop({
        type: Object,
        default: {},
    }) private data!: any; // 接收父组件传过来的值
    @Prop({
        type: String,
        default: null,
    }) private paySuccessId!: any; // 接收父组件传过来的值

    private setTime: any = null;
    private nowTime: number = 3;

    private baseUrl = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';

    // 查看详情
    @Emit('gotoDeatil')
    private gotoDeatil() {
        return this.data;
    }
    @Emit('gotoOrderList')
    private gotoOrderList() {
        return '3';
    }

    private mounted() {
        this.nowTime = 3;
        this.setTime = setInterval(() => {
            this.showCountDown(this.nowTime);
        }, 1000);
    }
    private destroyed() {
        clearInterval(this.setTime);
    }
    private showCountDown(nowTime: number) {
        this.nowTime--;
        if (this.nowTime <= 0) {
            if (this.paySuccessId) {
                this.gotoDeatil();
            } else {
                this.gotoOrderList();
            }
        }
    }
}
