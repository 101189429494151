
import { ElForm } from 'node_modules/element-ui/types/form';
import { Component, Vue, Prop, Watch, Emit  } from 'vue-property-decorator';
import ProvinceCitySelect from "@/components/ProvinceCitySelect.vue";

@Component({
    name: "InvoiceInfo",
    components: {
        ProvinceCitySelect,
    },
})
export default class InvoiceInfo extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    }) private exitFlag!: boolean; // 接收父组件传过来的值
    @Prop(Object) private invoiceInfo!: object;
    @Prop([]) private invoiceOrderIdList !: any;

    private showDialog: boolean = false; // 弹窗显示与关闭
    private formData: any = {};
    private caption: string = "开票";
    private submitLoading: boolean = false;
    private rules: any = {
        taxpayerNo: [
            { required: true, message: '请输入纳积人识别号', trigger: 'blur' },
            { max: 80, message: '纳税人识别号字符不能大于80个', trigger: 'blur' },
        ],
        invoiceCompanyName: [
            { required: true, message: '请输入开票公司名称', trigger: 'blur' },
            { max: 30, message: '开票公司字符不能大于30个', trigger: 'blur' },
        ],
        invoiceAddressTelephone: [
            { required: true, message: '请输入公司经营地址及电话', trigger: 'blur' },
            { max: 300, message: '公司经营地址及电话字符不能大于300个', trigger: 'blur' },
        ],
        invoiceOpeningBank: [
            { required: true, message: '请输入开户行及帐号', trigger: 'blur' },
            { max: 200, message: '开户行及帐号字符不能大于200个', trigger: 'blur' },
        ],
        invoiceContact: [
            { required: true, message: '请输入收件人', trigger: 'blur' },
            { max: 30, message: '收件人字符不能大于30个', trigger: 'blur' },
        ],
        invoiceMobile: [
            { required: true, message: '请输入收件人手机', trigger: 'blur' },
            { max: 20, message: '收件人手机字符不能大于20个', trigger: 'blur' },
        ],
        invoiceAreaStr: [
            { validator: this.validateAreaStr, trigger: 'blur' },
        ],
        invoiceAddress: [
            { required: true, message: '请输入详细地址', trigger: 'blur' },
            { max: 100, message: '详细地址字符不能大于100个', trigger: 'blur' },
        ],
    };
    private validateAreaStr(rule: any, value: string, callback: any) {
        if (!this.formData.areaProvinceCode || !this.formData.areaCityCode) {
            callback(new Error('请选择收件人所在地区'));
        } else {
            callback();
        }
    }
    @Watch('exitFlag') // 监听父组件传过来的值
    private changeFlagFun(value: boolean) {
        this.showDialog = value;
    }
    @Watch('invoiceInfo') // 监听父组件传过来的值
    private formDataFun(value: object) {
        this.formData = JSON.parse(JSON.stringify(value));
    }

    // 给父组件传值
    @Emit('infoCloseMsg')
    private closeTodo(num: number): number {
        return num;
    }
    //  确定按钮
    private closeDialog(num: number, submitName?: string) {
        if (num === 3) {
            // 判断校验是否通过
            if (submitName !== undefined) {
                const elForm = this.$refs[submitName]! as ElForm;
                elForm.validate((valid) => {
                    if (valid) {
                        this.submitLoading = true;
                        this.$httpService.postData({
                            ...this.formData,
                            orderList: this.invoiceOrderIdList,
                        }, '/apiProxy/api/frontend/orders/invoice')
                        .then((res: any) => {
                            this.submitLoading = false;
                            this.showDialog = false;
                            this.closeTodo(num); // 给父组件传值
                            this.$message({
                                message: '申请开票成功！',
                                type: 'success',
                            });
                        }).catch(() => {
                            this.submitLoading = false;
                        });
                    } else {
                        return false;
                    }
                });
            }
        } else {
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        }
    }
    // 弹窗关闭后清除所有数据
    private afterClose() {
        const elForm = this.$refs.formRef! as ElForm;
        elForm.resetFields();
    }
    // 右上角的关闭按钮
    private beforeclose() {
        this.showDialog = false;
        this.closeTodo(1); // 给父组件传值
    }
    private selectReturn(obj: any, str: any) {
        console.log(obj, str);
        this.formData.invoiceAreaId = obj.province;
        this.formData.areaProvinceCode = obj.province;
        this.formData.areaCityCode = obj.city;
        this.formData.invoiceAreaStr = `${str.province} ${str.city}`;
    }
}
