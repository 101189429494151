
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { useMessage } from '@/utils/message'; // 方便弹出吐司
import ContentTitle from "@/components/ContentTitle.vue";

@Component({
    name: "Evaluate",
    components: {
        ContentTitle,
    },
})
export default class Evaluate extends Vue {
    private baseUrl = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private contentTitle: string = "商品订单评价";
    private evaluateValue: number = 5;
    private evaluateDesc: string = "";
    private imageArray: any = [{
        imageList: [],
    }];
    private submitLoading = false;
    private formData: any = [{
            orderId: null,
            orderDetailId: null,
            evaluateValue: 5,
            evaluateDesc: '',
            imageList: [],
        }];
    @Prop({
        type: Object,
        default: {},
    }) private content!: any; // 接收父组件传过来的值
    @Prop({
        type: Function,
        default: () => '',
    }) private moneyFormat!: any;

    // 给父组件传值
    @Emit('infoReturn')
    private closeTodo(num: number): number {
        return num;
    }
    @Emit('evaluateSubmitMsg')
    private submitMsg(num: number): number {
        return num;
    }

    private created() {
        this.formData = [];
        this.imageArray = [];
        this.content.orderGoodsDetailList.map((item: any) => {
            this.formData.push({
                orderId: this.content.id,
                orderDetailId: item.orderDetailId,
                evaluateValue: 5,
                evaluateDesc: '',
                imageList: [],
            });
            this.imageArray.push({
                imageList: [],
            });
        });
    }
    private propGoBlack() {
        this.closeTodo(1);
    }
    // 删除图片
    private removeImg(index: number, imgindex: number) {
        this.formData[index].imageList.splice(imgindex, 1);
        this.imageArray[index].imageList.splice(imgindex, 1);
    }
    private clearInput(index: number) {
        const input: any = this.$refs[`uploadInput${index}`];
        input[0].value = "";
    }
    // 上传图片
    private chooseFile(event: any, index: number) {
        const fileData = event.target.files[0];
        const typeList = ['image/png', 'image/jpeg', 'image/jpg'];
        const fileSize = fileData.size / 1024 / 1024;
        if (this.formData[index].imageList.length > 4) {
            useMessage(3, '最多选择5张图片');
            this.clearInput(index);
        } else if (!typeList.includes(fileData.type)) {
            useMessage(3, '请上传jpg,jpeg,png文件格式');
            this.clearInput(index);
        } else if (fileSize > 2) {
            useMessage(3, '文件大小不能超过2M');
            this.clearInput(index);
        } else {
            // this.formData[index].imageList.push(URL.createObjectURL(fileData));
            const param = new FormData();
            param.append('file', fileData);
            this.$httpService.postData(param, '/apiProxy/api/frontend/file/upload_image', {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            }).then((res: any) => {
                this.formData[index].imageList.push(res.path);
                this.imageArray[index].imageList.push(this.baseUrl + res.path);
                this.clearInput(index);
            });
        }
    }
    private submit() {
        this.submitLoading = true;
        this.$httpService.postData({evaluateList: this.formData},
            `/apiProxy/api/frontend/orders/${this.content.id}/goods/evaluation`)
        .then((res: any) => {
            this.submitMsg(3);
            this.$message({
                message: '评价成功！',
                type: 'success',
            });
            this.submitLoading = false;
        }).catch(() => {
            this.submitLoading = false;
        });
    }
}
