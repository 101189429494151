
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
@Component({
  name: 'OrderItem',
})
export default class OrderItem extends Vue {
    @Prop({
        type: Object,
        default: {},
    }) private data!: any; // 接收父组件传过来的值

    private baseUrl = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';

    // 查看详情
    @Emit('gotoDeatil')
    private gotoDeatil() {
        return this.data;
    }
}
