
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import TimeSelectItem from "./TimeSelectItem.vue";
import { RESOURCE_CONFIG } from '@/utils/global';
import { assembleOrderConfirmBuyout } from '@/utils/assemble';

@Component({
    name: 'ProductItem',
    components: {
        TimeSelectItem,
    },
})
export default class ProductItem extends Vue {
    private RESOURCE_CONFIG: any = RESOURCE_CONFIG;
    private itemTab: any = {
        text: '',
    };
    @Prop({
        type: Boolean,
        default: false,
    }) private isDriveLicenseApply!: boolean; // 接收父组件传过来的值
    @Prop({
        type: Number,
        default: false,
    }) private addGoods!: number; // 接收父组件传过来的值
    @Prop({
        type: Boolean,
        default: true,
    }) private removeFlag!: boolean; // 接收父组件传过来的值
    @Prop({
        type: Boolean,
        default: false,
    }) private isServerRenew!: boolean; // 接收父组件传过来的值
    @Prop({
        type: Boolean,
        default: false,
    }) private renewFlag!: boolean; // 接收父组件传过来的值
    @Prop({
        type: Boolean,
        default: false,
    }) private buyCompletelyFlag!: boolean; // 接收父组件传过来的值
    @Prop({
        type: Object,
        default: {},
    }) private itemData!: any; // 接收父组件传过来的值
    private created() {
        this.itemTab = Object.assign({}, this.itemData);
        // 买断的时候，不显示 服务器 和 云用户数
        if (this.buyCompletelyFlag) {
            // 买断产品和授权用户数 上下显示
            const detailListCopy: any = assembleOrderConfirmBuyout(this.itemTab.detailList);
            this.itemTab.detailList = [].concat(detailListCopy);
        }
    }
    // 给父组件传值
    @Emit('clickRemoveProduct')
    private clickRemoveProductTodo(productItem: any): any {
        return productItem;
    }
    private removeProduct(productItem: any) {
        this.clickRemoveProductTodo(productItem);
    }
    // 给父组件传值
    @Emit('clickAddDrive')
    private clickAddDriveTodo(productItem: any): any {
        return productItem;
    }
    private addDrive(productItem: any) {
        this.clickAddDriveTodo(productItem);
    }
    @Emit('selectItemTime')
    private selectItemTimeTodo(productItem: any): any {
        return productItem;
    }
    private propHandleSelect(tab: any) {
        this.selectItemTimeTodo(tab);
    }
}
