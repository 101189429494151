
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import ChooseBuyItem from "../../../resourceManage/buy/component/ChooseBuyItem.vue";
import ChooseDialog from "../../../resourceManage/buy/component/ChooseDialog.vue";
import { moneyFormat } from '@/utils/utils';
import { RESOURCE_CONFIG, SERVER_CONFIG, SERVER_CONFIG_MC_FOUR } from '@/utils/global';
@Component({
    name: 'AddPurchaseDrawer',
    components: {
        ChooseBuyItem,
        ChooseDialog,
    },
})
export default class AddPurchaseDrawer extends Vue {
    @Prop({
        type: String,
        default: '',
    }) private userId!: string; // 接收父组件传过来的值
    @Prop({
        type: Object,
        default: {},
    }) private companyServerFourObj!: any; // 接收父组件传过来的值
    private pageSize: number = 16;
    private itemTab: any = {
        text: 'AddPurchaseDrawer',
    };
    private goodsList: any = [];
    // 数据库的cpu
    private dbCpuSetList: any = [];
    private dbFreeCpu: number = 0;
    private dbCpu: number = 0;
    // 数据库的memory
    private dbMemorySetList: any = [];
    private dbFreeMemory: number = 0;
    private dbMemory: number = 0;
    // 数据库的HardDisk
    private dbHardDiskSetList: any = [];
    private dbFreeHardDisk: number = 0;
    private dbHardDisk: number = 0;
    // 数据库的timeNumber
    private dbTimeNumberSetList: any = [];
    private dbFreeTimeNumber: number = 0;
    private dbTimeNumber: number = 0;
    // 判断是否已经选择数据库
    private isSelectDB: boolean = false;
    // 数据库个数
    private dbNumber: number = 0;
    // 产品默认时间列表
    private appTimeNumberSetList: any = [];
    private dbTypeArr: any = [
        {
            dbType: 1,
            dbTypeName: 'PostgreSQL',
        },
        {
            dbType: 2,
            dbTypeName: 'Oracle',
        },
    ];
    private dbType = -1;
    private productData: any = {
        pageNo: 1,
        pageSize: 8,
        productName: '',
    };
    // SaaS产品列表
    private productSaaSList: any = [];
    private productSaaSTotal: number = 0;
    // app产品列表
    private productAppList: any = [];
    private productAppTotal: number = 0;
    // 加载
    private loading: boolean = true;
    private disabledScroll: boolean = false;
    private disabledSaasScroll: boolean = false;
    // Oracle goods模板
    private oracleGoods: any = {
        goodsId: 3, // 选中商品传给后端的值
        appName: 'Oracle',
        orderDetailNumber: 1, // 选中商品数量(累加)
        orderDetailTimeNumber: this.dbTimeNumber, // 时长
        cpu: this.dbCpu,
        memory: this.dbMemory,
        harddisk: this.dbHardDisk,
        orderGoodsSceneType: RESOURCE_CONFIG.ORACLE_GOODSSCENETYPE,
    };
    // 选择的产品列表
    private selectProductList: any = [];
    // 数据库价格
    private dbTab: any = {
        totalMoney: 0,
        payMoney: 0,
    };
    // 所有商品价格
    private allProductTab: any = {
        serverNum: 0,
        serverMoney: 0,
        appNum: 0, // 产品个数
        appMoney: 0, // 产品价格
        dbNum: 0, // 数据库个数
        dbMoney: 0, // 数据库价格
        discountMoney: 0,
        totalMoney: 0,
    };
    // 选购
    private chooseFlag: boolean = false;
    private serviceItemData: any = {};
    // 已选的数据库
    private selectedDBData: any = {};
    // 4.0正式服务器列表
    private companyServerFourList: any = [];
    // 4.0试用服务器参数
    private companyServerFourTrialObj: any = {};
    // 服务器的授权用户数
    private userNumber: number = 0;
    @Prop({
        type: Array,
        default: [],
    }) private itemData!: any; // 接收父组件传过来的值
    @Watch('itemData', {immediate: true, deep: true}) // 监听父组件传过来的值
    private async itemDataFun(value: any) {
        this.goodsList = [].concat(value);
        const goodsList = this.goodsList || [];
        goodsList.filter((item: any) => {
            if (item.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID) {
                this.dbType = 2;
                this.dbCpu = item.cpu;
                this.dbMemory = item.memory;
                this.dbHardDisk = item.harddisk;
                this.dbTimeNumber = item.orderDetailTimeNumber;
                this.isSelectDB = true;
            }
        });
        // 过滤单独mc4.0平台的情况
        const appIndex = goodsList.findIndex((item: any) => (
            item.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE
            &&
            item.goodsId !== RESOURCE_CONFIG.MOREDEV_GOODSID
        ));
        if (appIndex !== -1 && !this.isSelectDB) {
            this.dbType = 1;
            this.isSelectDB = true;
        }
        this.disabledScroll = true;
        this.disabledSaasScroll = true;
        if (this.dbType === 2 && this.isSelectDB) {
            this.getDBPrice();
        }
        await this.getSetInfo();
        await this.initProduct();
    }
    private async created() {
        console.log('created');
        this.getCompanyServer();
    }
    // 获得个人的 服务器列表
    private async getCompanyServer() {
        const paramUserId = this.userId !== '' ? this.userId : this.$store.state.userInfo.id;
        return new Promise((resolve, reject) => {
            this.$httpService.getData({}, '/apiProxy/api/frontend/users/company-server/' + paramUserId)
            .then((res: any) => {
                // 4.0正式
                this.companyServerFourList = [];
                res.serverInfoList.forEach((item: any) => {
                    if (item.serverType === SERVER_CONFIG_MC_FOUR.SERVER_TYPE) {
                        this.companyServerFourList.push(item);
                    }
                });
                const objMCFourTrial = res.serverInfoList
                        .find((item: any) => item.serverType  === SERVER_CONFIG_MC_FOUR.SERVER_TYPE_TRIAL);
                this.companyServerFourTrialObj = Object.assign({}, objMCFourTrial);
                resolve(res);
            });
        });
    }
    private get getDbNumber() {
        const selectedOracleLen = this.selectProductList
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID).length;
        const selectedAppLen = this.selectProductList
                .filter((el: any) => el.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE).length;
        if (selectedOracleLen > 0 ) {
            return this.allProductTab.dbNum;
        } else if (selectedAppLen > 0) {
            return selectedAppLen;
        }
        return 0;
    }
    private async initProduct() {
        this.loading = true;
        this.disabledScroll = true;
        this.disabledSaasScroll = true;
        this.dbNumber = 0;
        this.dbTab = {
            totalMoney: 0,
            payMoney: 0,
        };
        this.allProductTab = {
            serverNum: 0,
            serverMoney: 0,
            appNum: 0, // 产品个数
            appMoney: 0, // 产品价格
            dbNum: 0, // 数据库个数
            dbMoney: 0, // 数据库价格
            discountMoney: 0,
            totalMoney: 0,
        };
        this.selectProductList = [];
        this.productData.pageNo = 1;
        await this.getSaaSList(this.productData.pageNo);
        await this.getAppList(this.productData.pageNo);
        this.$nextTick(() => {
            this.loading = false;
        });
    }
    private async getSetInfo() {
        // 得到系统商品相关的配置
        const paramData: any = {};
        paramData.preventRepeat = 'addPurchase';
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/goods/set-info')
        .then((res: any) => {
            // 配置数据库的Cpu
            this.dbCpuSetList = [];
            this.dbFreeCpu =
                this.dbCpu > 0 ? this.dbCpu : res.dbCpuSetList[0].number;
            this.dbCpu = this.dbFreeCpu;
            res.dbCpuSetList.filter((item: any) => {
                 this.dbCpuSetList.push(item);
            });
            // 配置数据库的Memory
            this.dbMemorySetList = [];
            this.dbFreeMemory =
                this.dbMemory > 0 ? this.dbMemory : res.dbMemorySetList[0].number;
            this.dbMemory = this.dbFreeMemory;
            res.dbMemorySetList.filter((item: any) => {
                this.dbMemorySetList.push(item);
            });
            // 配置数据库的HardDisk
            this.dbHardDiskSetList = [];
            this.dbFreeHardDisk =
                this.dbHardDisk > 0 ? this.dbHardDisk : res.dbHardDiskSetList[0].number;
            this.dbHardDisk = this.dbFreeHardDisk;
            res.dbHardDiskSetList.filter((item: any) => {
                this.dbHardDiskSetList.push(item);
            });
            // 配置数据库的TimeNumber
            this.dbTimeNumberSetList = [];
            this.dbFreeTimeNumber =
                this.dbTimeNumber > 0 ? this.dbTimeNumber : res.timeNumberSetList[0].number;
            this.dbTimeNumber = this.dbFreeTimeNumber;
            res.timeNumberSetList.filter((item: any) => {
                this.dbTimeNumberSetList.push(item);
            });
            // 配置产品时间列表
            this.appTimeNumberSetList = res.appTimeNumberSetList;
            // 得到免费服务器的授权用户数
            res.userLimitList.filter((item: any) => {
                if (item.price === 0) {
                    this.userNumber = item.number;
                }
            });
        });
    }
    private async getSaaSList(pageIndex: number) {
        this.disabledSaasScroll = true;
        if (this.productSaaSTotal > 0 && (this.productSaaSTotal / this.pageSize  - pageIndex) < -1 ) {
            this.disabledSaasScroll = true;
            return;
        }
        // 得到SAAS 商品列表
        const paramData: any = {};
        paramData.pageNo = pageIndex;
        paramData.pageSize = this.pageSize;
        paramData.goodsType = 7;
        // if (this.dbType !== -1) {
        //     paramData.dbType = this.dbType;
        // }
        paramData.selectedGoodsId = [];
        this.goodsList.forEach((item: any, index: number) => {
            if (item.orderGoodsSceneType === 20 || item.orderGoodsSceneType === 21) {
                paramData.selectedGoodsId.push(item.goodsId);
            }
        });
        if (this.productData.productName !== '') {
            paramData.name = this.productData.productName;
        }
        if (pageIndex === 1) {
            this.productSaaSList = [];
        }
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/goods/saas-app')
        .then((res: any) => {
            this.disabledSaasScroll = false;
            // this.productSaaSList = this.productSaaSList.concat(res.list);
            res.list.filter((item: any) => {
                const selectedLen = this.selectProductList
                    .filter((el: any) => el.goodsId === item.id).length;
                item.isSelect = selectedLen > 0 ? true : false;
                item.orderDetailTimeNumber = 0;
                this.productSaaSList.push(item);
            });
            this.productSaaSTotal = res.total;
        });
    }
    private async getAppList(pageIndex: number) {
        this.disabledScroll = true;
        if (this.productAppTotal > 0 && (this.productAppTotal / this.pageSize - pageIndex) < -1 ) {
            this.disabledScroll = true;
            return;
        }
        this.loading = true;
        // 得到应用 商品列表
        const paramData: any = {};
        paramData.pageNo = pageIndex;
        paramData.pageSize = this.pageSize;
        paramData.goodsType = 6;
        // if (this.dbType !== -1) {
        //     paramData.dbType = this.dbType;
        // }
        paramData.selectedGoodsId = [];
        this.goodsList.forEach((item: any, index: number) => {
            if (item.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE) {
                paramData.selectedGoodsId.push(item.goodsId);
            }
        });
        if (this.productData.productName !== '') {
            paramData.name = this.productData.productName;
        }
        if (pageIndex === 1) {
            this.productAppList = [];
        }
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/goods/saas-app')
        .then((res: any) => {
            this.disabledScroll = false;
            // this.productAppList = this.productAppList.concat(res.list);
            res.list.filter((item: any) => {
                const selectedLen = this.selectProductList
                    .filter((el: any) => el.goodsId === item.id).length;
                item.isSelect = selectedLen > 0 ? true : false;
                item.orderDetailTimeNumber = 0;
                // 过滤 MoreDev 平台
                const isMoreDev = item.mcVersion === '4.0' && item.id === RESOURCE_CONFIG.MOREDEV_GOODSID;
                if (!isMoreDev) {
                    this.productAppList.push(item);
                }
            });
            this.productAppTotal = res.total;
            this.loading = false;
        });
    }
    private async loadScroll() {
        console.log('loadScroll');
        this.productData.pageNo += 1;
        await this.getSaaSList(this.productData.pageNo);
        await this.getAppList(this.productData.pageNo);
    }
    private async dbClick(dbClickType: number) {
        if (this.isSelectDB) {
            return;
        }
        this.$message.warning('请在选购产品中选择数据库！');
    }
    private async searchHandle() {
        await this.initProduct();
    }
    private getDBPrice() {
        const paramData: any = {};
        paramData.goodsList	= [];
        if (this.dbType === 2) {
            this.oracleGoods.cpu  = this.dbCpu;
            this.oracleGoods.memory  = this.dbMemory;
            this.oracleGoods.harddisk  = this.dbHardDisk;
            this.oracleGoods.orderDetailTimeNumber  = this.dbTimeNumber;
            this.oracleGoods.orderDetailNumber = 1;
            paramData.goodsList.push(this.oracleGoods);
        }
        paramData.userCheck = false;
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/goods/price')
        .then((res: any) => {
            this.dbTab.payMoney = res.payMoney;
            this.dbTab.totalMoney = res.totalMoney;
        });
    }
    private alertMsg(err: any, paramData: any) {
        if (err.code === 400010) {
            this.$alert(err.message, '提示', {
                showClose: false,
                confirmButtonText: '确定',
                callback: (action) => {
                    // ...
                },
            });
            return;
        }
        const hasAppLen = paramData.goodsList
            .filter((item: any) => item.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE).length;
        let serverDisabledCpu = 0;
        let serverDisableMemory = 0;
        let serverDisableHardDisk = 0;
        if (hasAppLen <= 2) {
            serverDisabledCpu = SERVER_CONFIG.TWO_VCUP;
            serverDisableMemory = SERVER_CONFIG.TWO_VCUP_MEMORY;
            serverDisableHardDisk = SERVER_CONFIG.TWO_VCUP_HARDDISK;
        } else if (hasAppLen > 2 && hasAppLen <= 4) {
            serverDisabledCpu = SERVER_CONFIG.FOUR_VCUP;
            serverDisableMemory = SERVER_CONFIG.FOUR_VCUP_MEMORY;
            serverDisableHardDisk = SERVER_CONFIG.FOUR_VCUP_HARDDISK;
        } else if (hasAppLen > 4 && hasAppLen <= 6) {
            serverDisabledCpu = SERVER_CONFIG.SIX_VCUP;
            serverDisableMemory = SERVER_CONFIG.SIX_VCUP_MEMORY;
            serverDisableHardDisk = SERVER_CONFIG.SIX_VCUP_HARDDISK;
        } else if (hasAppLen > 6 && hasAppLen <= 8) {
            serverDisabledCpu = SERVER_CONFIG.EIGHT_VCUP;
            serverDisableMemory = SERVER_CONFIG.EIGHT_VCUP_MEMORY;
            serverDisableHardDisk = SERVER_CONFIG.EIGHT_VCUP_HARDDISK;
        } else if (hasAppLen > 8 && hasAppLen <= 12) {
            serverDisabledCpu = SERVER_CONFIG.TWELVE_VCUP;
            serverDisableMemory = SERVER_CONFIG.TWELVE_VCUP_MEMORY;
            serverDisableHardDisk = SERVER_CONFIG.TWELVE_VCUP_HARDDISK;
        }
        if (hasAppLen > 12) {
            this.$alert('应用购买已达上限，不允许继续选购应用产品', '提示', {
                confirmButtonText: '确定',
                type: 'warning',
                callback: (action) => {
                    // ...
                },
            });
            return;
        }
        if (err.code === 3004 || err.code === 3005 || err.code === 3006) {
            // 4.0 资源不足提示
            const goodsListFourApplicationLen = paramData.goodsList
            .filter((el: any) => (el.mcVersion === '4.0' )).length;
            if (goodsListFourApplicationLen >= 1) {
                this.$alert(`
                云服务器资源不足，
                无法继续加购，
                4.0云服务器初始配置4核/16G/60G，后续每个应用需要增加1核/4G/10G，
                请在订单确认页将云服务器配置调整后再继续加购商品`, '资源不足', {
                    showClose: false,
                    confirmButtonText: '确定',
                    callback: (action) => {
                        // ...
                    },
                });
                return;
            }
            this.$alert(`云服务器资源不足，
                无法继续加购，
                请在订单确认页将云服务器配置调整为
                ${serverDisabledCpu}vCPU/
                ${serverDisableMemory}G内存/
                ${serverDisableHardDisk}G硬盘后再继续加购商品`, '资源不足', {
                showClose: false,
                confirmButtonText: '确定',
                callback: (action) => {
                    // ...
                },
            });
        } else if (err.code === 400001 || err.code === 400002 || err.code === 400003
            || err.code === 400010 || err.code === 400011 || err.code === 400013) {
            // buyFlag=1 没有提示的errCode提示
            this.$alert(err.message, '提示', {
                showClose: false,
                confirmButtonText: '我知道了',
                callback: (action) => {
                    // ...
                },
            });
        }
    }
    // 给父组件传值
    @Emit('clickConfirmAdd')
    private clickConfirmAddTodo(): any {
        const goodsList: any = [];
        this.selectProductList.forEach((item: any, index: number) => {
            if (item.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID && this.dbType === 2 && this.allProductTab.dbNum > 0) {
                this.oracleGoods.orderDetailNumber = this.allProductTab.dbNum;
                goodsList.push(this.oracleGoods);
            }
            goodsList.push(item);
        });
        return goodsList;
    }
    private confirmAdd() {
        console.log('this.selectProductList', this.selectProductList);
        if (this.selectProductList.length === 0) {
            this.$message.warning('请先选择产品');
            return;
        }
        this.clickConfirmAddTodo();
    }
    // 金额格式化
    private moneyFormat(value: any) {
        return moneyFormat(value, 2);
    }
    // 点击选购按钮
    private propBuyChoose(value: any) {
        this.chooseStartValidate(value);
    }
    // 选购前先统一验证数量和数据库
    private chooseStartValidate(item: any) {
        // 订单确认页产品中 是否有 oracle
        const goodsListOracleLen = this.goodsList
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID).length;
        // 订单确认页产品中 是否有 应用
        const goodsListAppLen = this.goodsList
                .filter((el: any) => el.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE).length;
        const selectedOracleLen = this.selectProductList
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID).length;
        const selectedAppLen = this.selectProductList
                .filter((el: any) => el.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE).length;
        const selectedThirdAppLen = this.selectProductList
                .filter((el: any) => el.mcVersion === '3.0').length;
        const selectedFourAppLen = this.selectProductList
                .filter((el: any) => el.mcVersion === '4.0').length;
        if (
            (selectedThirdAppLen > 0 && item.mcVersion === '4.0')
            ||
            (selectedFourAppLen > 0 && item.mcVersion === '3.0')
            ) {
            // 提示
            this.$confirm(`您所选择的商品中包含新旧版本应用，不能同时购买，请分开下单！`, '新旧版本应用', {
                confirmButtonText: '我知道了',
                cancelButtonText: '取消',
            }).then(() => {
                // 确定
            }).catch(() => {
                // 取消
            });
            return;
        }
        // 已选数据库,产品只支持另一种数据库判断
        if (
            (
                // 如果加购选中或者订单确认页有oracle，有应用
                (selectedOracleLen + goodsListOracleLen) > 0
                && item.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE
                && item.dbTypes && item.dbTypes.indexOf('2') === -1
            )
            ||
            (
                // 如果加购选中有应用，但是没有oracle数据库
                // 如果订单确认页有应用，但是没有oracle数据库
                (selectedOracleLen + goodsListOracleLen) === 0
                && (selectedAppLen + goodsListAppLen) > 0
                && item.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE
                && item.dbTypes && item.dbTypes.indexOf('1') === -1
            )
            ) {
            this.$confirm(`暂不支持同时下单购买不同数据库类型的应用，如有需要，请分开下单；`, '数据库类型不一致', {
                confirmButtonText: '我知道了',
                cancelButtonText: '取消',
            }).then(() => {
                // 确定
            }).catch(() => {
                // 取消
            });
            return;
        }
        this.selectedDBData = {
            appName: '',
        };
        // 已选了oracle
        if (this.isSelectDB && this.dbType === 2) {
            this.selectedDBData = this.goodsList
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID)[0];
        }
        if (selectedOracleLen > 0 && item.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE) {
            this.selectedDBData = this.selectProductList
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID)[0];
        }
        // 已选pg
        if (this.isSelectDB && this.dbType === 1) {
            this.selectedDBData = {
                appName: 'PostgreSQL',
            };
        }
        if (selectedOracleLen === 0 && selectedAppLen > 0
            && item.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE) {
            this.selectedDBData = {
                appName: 'PostgreSQL',
            };
        }
        this.serviceItemData = item;
        this.chooseFlag = true;
    }
    // 取消选择产品
    private propCancelApplication(value: any) {
        const valueArr = [];
        const itemData = {
            appName: value.name,
            goodsId: value.id, // 选中商品传给后端的值
            goodsPriceType: value.goodsPriceType,
        };
        valueArr.push(itemData);
        this.getChoosePrice(valueArr, false).then(() => {
            // 订单确认页产品中 是否有 应用
            const goodsListAppLen = this.goodsList
                .filter((el: any) => (
                    el.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE
                    // 排除平台应用,后期如果有oracle配置需要修改
                    && el.goodsId !== RESOURCE_CONFIG.MOREDEV_GOODSID
                )).length;
            const selectedAppLen = this.selectProductList
                .filter((el: any) => el.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE).length;
            // 当确认页列表和选择列表 都没有应用的情况，不包含平台
            if (goodsListAppLen === 0 && selectedAppLen === 0) {
                this.dbType = -1;
                this.dbTab.totalMoney = 0;
                this.dbTab.payMoney = 0;
                this.isSelectDB = false;
            }
            if (value.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE) {
                this.productAppList.forEach((appItem: any) => {
                    if (appItem.id === value.id) {
                        appItem.isSelect = false;
                    }
                });
            } else if (value.goodsPriceType === RESOURCE_CONFIG.SAAS_GOODSPRICETYPE) {
                this.productSaaSList.forEach((appItem: any) => {
                    if (appItem.id === value.id) {
                        appItem.isSelect = false;
                    }
                });
            }
            this.$message.success(`您已移除该产品`);
        });
    }
    // 选购成功开始接口认证
    private propChooseClose(value: any) {
        this.chooseFlag = false;
        if (value.length === 0) {
            return;
        }
        // 订单确认页产品中 除了 服务还有多少
        const goodsListNoServiceList = this.goodsList
                .filter((el: any) => el.orderGoodsSceneType !== RESOURCE_CONFIG.SERVICE_GOODSSCENETYPE);
        // 选购，是否有 授权用户数=买断
        const valueGrantList = value.filter((el: any) => el.goodsId === RESOURCE_CONFIG.GRANT_GOODSID);
        // 已选购 除了 服务还有多少
        const selectedNoServiceList = this.selectProductList
                .filter((el: any) => el.orderGoodsSceneType !== RESOURCE_CONFIG.SERVICE_GOODSSCENETYPE);
        // 如果是先买服务，就还可以买断，其它不能
        if ((goodsListNoServiceList.length > 0 || selectedNoServiceList.length > 0) && valueGrantList.length > 0) {
            // 提示
            this.$confirm(`您所选择的商品中“买断”类型的应用，只能和服务类商品同时购买，不能和其它类型商品同时购买，请分开下单！`, '提示', {
                confirmButtonText: '我知道了',
                cancelButtonText: '取消',
            }).then(() => {
                // 确定
            }).catch(() => {
                // 取消
            });
            return;
        }
        this.computeAllPrice(value).then(() => {
            this.getChoosePrice(value, true).then(() => {
                // 订单确认页产品中 是否有 oracle
                const goodsListOracleLen = this.goodsList
                        .filter((el: any) => el.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID).length;
                const goodsListAppLen = this.goodsList
                        .filter((el: any) => (
                            el.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE
                            // 排除平台应用,后期如果有oracle配置需要修改
                            && el.goodsId !== RESOURCE_CONFIG.MOREDEV_GOODSID
                        )).length;
                const selectedOracleLen = this.selectProductList
                    .filter((el: any) => el.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID).length;
                const selectListAppLen = this.selectProductList
                        .filter((el: any) => el.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE).length;
                value.forEach((item: any) => {
                    // 当确认页列表和选择列表 都没有应用的情况，不包含平台
                    if (goodsListAppLen === 0 && selectListAppLen === 0) {
                        this.dbType = -1;
                        this.dbTab.totalMoney = 0;
                        this.dbTab.payMoney = 0;
                        this.isSelectDB = false;
                    } else {
                        // 当确认页列表和选择列表 都没有oracle的情况
                        if (goodsListOracleLen === 0 && selectedOracleLen === 0) {
                            this.dbType = 1;
                            this.dbTab.totalMoney = 0;
                            this.dbTab.payMoney = 0;
                        } else {
                            this.dbType = 2;
                        }
                        this.isSelectDB = true;
                    }
                    if (item.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE) {
                        this.productAppList.forEach((appItem: any) => {
                            if (appItem.id === item.goodsId) {
                                appItem.isSelect = true;
                            }
                        });
                    } else if (item.orderGoodsSceneType === RESOURCE_CONFIG.SAAS_GOODSSCENETYPE
                    || item.orderGoodsSceneType === RESOURCE_CONFIG.SAAS_GOODSSCENETYPE_RENEW) {
                        this.productSaaSList.forEach((appItem: any) => {
                            if (appItem.id === item.goodsId) {
                                appItem.isSelect = true;
                            }
                        });
                    }
                    // 当确认页列表没有应用没有oracle的情况，需要请求拿到一下数据库的信息显示到相应地方
                    if (goodsListAppLen === 0 && goodsListOracleLen === 0
                        && item.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID) {
                        this.dbType = 2;
                        this.dbCpu = item.cpu;
                        this.dbMemory = item.memory;
                        this.dbHardDisk = item.harddisk;
                        this.dbTimeNumber = item.orderDetailTimeNumber;
                        this.isSelectDB = true;
                        this.getDBPrice();
                    }
                });
                this.$message.success(`已成功添加`);
            });
        });
    }
    // 计算价格添加，没有问题的情况下加入到已选列表中
    private getChoosePrice(itemDataList: any, isAdd: boolean) {
        const paramData: any = {};
        paramData.goodsList	= [];
        // 已选购产品中 是否有 oracle
        const selectedOracle = this.selectProductList
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID);
        // 当前选购产品中 是否有 oracle
        const chosenOracle = itemDataList
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID);
        // 当前选购产品中 除了oracle数据库 理论上剩下来的都是应用，如果数量大于1，说明数据有问题
        const chosenProductList = itemDataList
                .filter((el: any) => el.goodsId !== RESOURCE_CONFIG.ORACLE_GOODSID);
        if (chosenProductList.length !== 1) {
            this.$message.warning('选购的产品有误');
        }
        const itemData = chosenProductList[0];
        // 把已选的产品，除了服务器、用户数、oracle数据库先全部赛选出来，因为这三个比较特殊需要特殊处理一遍
        this.selectProductList.forEach((item: any, index: number) => {
            if (item.goodsId !== RESOURCE_CONFIG.SERVER_GOODSID
                && item.goodsId !== RESOURCE_CONFIG.USERNUMBER_GOODSID
                && item.goodsId !== RESOURCE_CONFIG.ORACLE_GOODSID) {
                if (!isAdd && item.goodsId === itemData.goodsId) {
                    // ...
                } else {
                    paramData.goodsList.push(item);
                }
            }
        });
        if (itemData.mcVersion === '4.0'
            && this.companyServerFourObj.serverId
            && this.companyServerFourObj.serverId.toString() !== '-1') {
            itemData.serverId = this.companyServerFourObj.serverId;
        }
        if (isAdd) {
            paramData.goodsList.push(itemData);
        }
        // 添加数据库
        let isOracle = false;
        let oracleData: any = {};
        // 查看选择的产品是是否有oracle数据库，如果有的，做数量的修改
        if (selectedOracle.length > 0) {
            oracleData = Object.assign({}, selectedOracle[0]);
            // 判断选购列表中是否有oracle数据库，有并且下一个添加的是应用这需要对oracle数据进行操作
            if (isAdd && itemData.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE) {
                oracleData.orderDetailNumber += 1;
            // 替换的时候只有goodsPriceType，添加的时候只有orderGoodsSceneType
            } else if (!isAdd && itemData.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE) {
                oracleData.orderDetailNumber -= 1;
            }
        } else if (selectedOracle.length === 0 && chosenOracle.length > 0) {
            oracleData = Object.assign({}, chosenOracle[0]);
            if (isAdd) {
                oracleData.orderDetailNumber = 1;
            }
        }
        if (oracleData && oracleData.name !== '' && oracleData.orderDetailNumber > 0) {
            isOracle = true;
            const oracleObj = {
                goodsId: RESOURCE_CONFIG.ORACLE_GOODSID, // 选中商品传给后端的值
                appName: 'Oracle',
                orderDetailNumber: oracleData.orderDetailNumber, // 选中商品数量(累加)
                orderDetailTimeNumber: oracleData.orderDetailTimeNumber, // 时长
                cpu: oracleData.cpu,
                memory: oracleData.memory,
                harddisk: oracleData.harddisk,
                orderGoodsSceneType: RESOURCE_CONFIG.ORACLE_GOODSSCENETYPE,
            };
            paramData.goodsList.push(oracleObj);
            paramData.goodsList.forEach((item: any) => {
                // 添加到请求参数的应用，除了MoreDev 平台，其它的多添加上oracle配置
                if (item.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE
                    && item.goodsId !== RESOURCE_CONFIG.MOREDEV_GOODSID) {
                    item.cpu = oracleData.cpu;
                    item.memory = oracleData.memory;
                    item.harddisk = oracleData.harddisk;
                }
            });
        }
        if (paramData.goodsList.length === 0) {
            return new Promise((resolve, reject) => {
                this.allProductTab = {
                    serverNum: 0,
                    serverMoney: 0,
                    appNum: 0, // 产品个数
                    appMoney: 0, // 产品价格
                    dbNum: 0, // 数据库个数
                    dbMoney: 0, // 数据库价格
                    discountMoney: 0,
                    totalMoney: 0,
                };
                this.selectProductList = [].concat(paramData.goodsList);
                resolve({success: true});
            });
        }
        if (this.userId !== '') {
            paramData.userList = unescape(this.userId).split(',');
        }
        // 这里不需要验证相关数据，因为这里只需要获得当前选购的产品的 价格数量
        paramData.userCheck = false;
        return new Promise((resolve, reject) => {
            this.$httpService.postData(paramData, '/apiProxy/api/frontend/goods/price', {}, 1)
            .then((res: any) => {
                if (res.success) {
                    this.allProductTab = {
                        serverNum: res.serverNum,
                        serverMoney: res.serverMoney,
                        appNum: res.appNum, // 产品个数
                        appMoney: res.appMoney, // 产品价格
                        dbNum: res.dbNum, // 数据库个数
                        dbMoney: res.dbMoney, // 数据库价格
                        discountMoney: res.discountMoney,
                        totalMoney: res.totalMoney,
                    };
                    this.selectProductList = [].concat(paramData.goodsList);
                    console.log('this.selectProductList', this.selectProductList);
                    resolve(res);
                }
            }).catch((err: any) => {
                this.alertMsg(err, paramData);
            });
        });
    }
    // 验证所有的产品 是否能一起添加
    private computeAllPrice(itemDataArr: any) {
        console.log('computeAllPrice itemDataArr', itemDataArr);
        console.log('computeAllPrice this.companyServerFourObj', this.companyServerFourObj);
        const paramData: any = {};
        paramData.goodsList	= [];
        const goodsList = this.goodsList;
        let dbNumberCurrent = 0;
        // let isNewMoreDev = false;
        // let serverTimeNumber = 0;
        // let serverDBType = 0;
        goodsList.forEach((item: any) => {
            if (item.goodsId !== RESOURCE_CONFIG.ORACLE_GOODSID) {
                paramData.goodsList.push(item);
            } else {
                dbNumberCurrent += item.orderDetailNumber;
            }
        });
        this.selectProductList.forEach((item: any, index: number) => {
            if (item.goodsId !== RESOURCE_CONFIG.ORACLE_GOODSID) {
                paramData.goodsList.push(item);
            } else {
                dbNumberCurrent += item.orderDetailNumber;
            }
        });
        // 当前选购产品中 是否有 oracle
        const chosenOracle = itemDataArr
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID);
        if (chosenOracle.length > 0) {
            this.dbType = 2;
        }
        // 当前选购产品中 是否有 应用
        itemDataArr.forEach((item: any) => {
            // 添加到请求参数的应用，除了MoreDev 平台，其它的多添加上oracle配置
            if (chosenOracle.length > 0
                && item.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE
                && item.goodsId !== RESOURCE_CONFIG.MOREDEV_GOODSID) {
                item.cpu = this.dbCpu;
                item.memory = this.dbMemory;
                item.harddisk = this.dbHardDisk;
            }
            if (item.goodsId !== RESOURCE_CONFIG.ORACLE_GOODSID) {
                paramData.goodsList.push(item);
            } else {
                dbNumberCurrent += item.orderDetailNumber;
                this.dbCpu = item.cpu;
                this.dbMemory = item.memory;
                this.dbHardDisk = item.harddisk;
            }
        });
        if (this.dbType === 2 && dbNumberCurrent > 0) {
            this.oracleGoods.cpu  = this.dbCpu;
            this.oracleGoods.memory  = this.dbMemory;
            this.oracleGoods.harddisk  = this.dbHardDisk;
            this.oracleGoods.orderDetailTimeNumber  = this.dbTimeNumber;
            this.oracleGoods.orderDetailNumber = dbNumberCurrent;
            paramData.goodsList.push(this.oracleGoods);
        }
        paramData.userCheck = true;
        if (paramData.goodsList.length === 0) {
            return new Promise((resolve, reject) => {
                resolve({success: true});
            });
        }
        return new Promise((resolve, reject) => {
            this.$httpService.postData(paramData, '/apiProxy/api/frontend/goods/price', {}, 1)
            .then((res: any) => {
                if (res.success) {
                    resolve(res);
                }
            }).catch((err: any) => {
                this.alertMsg(err, paramData);
            });
        });
    }
}
