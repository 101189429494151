
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import batchDownload from '@/utils/batchDownload';
import CreateDriveAuth from './CreateDriveAuth.vue';

@Component({
    name: 'DriveAuthorizeDrawer',
    components: {
        CreateDriveAuth,
    },
})
export default class DriveAuthorizeDrawer extends Vue {
    private driveData: any = [];
    private driveLicense: any = {};
    private rowData: any = {};
    private spanArr: any = [];
    private currentRow: any = {};
    private showDialog: boolean = false;    // 生成驱动授权弹窗

    @Prop(Object) private content!: object;
    @Watch('content', {immediate: true, deep: true}) // 监听父组件传过来的值
    private contentFun(value: object) {
        this.rowData = JSON.parse(JSON.stringify(value));
    }

    private created() {
        this.getDriveDetail();
        this.getDriveLicense();
    }

    private getDriveDetail() {
        this.$httpService.postData({}, `/apiProxy/api/frontend/orders/${this.rowData.id}/goods/drive`)
        .then((res: any) => {
            if (res && res.length > 0) {
                this.driveData = res;
                if (this.driveData.length > 0) {
                    this.driveData.sort((a: any, b: any) => a.authCodeId - b.authCodeId);
                }
                this.getSpanArr(res);
            } else {
                this.driveData = [];
                this.spanArr = [];
            }
        });
    }
    private getDriveLicense() {
        this.$httpService.getData({}, `/apiProxy/api/frontend/orders/${this.rowData.id}/drive/license/overview`)
        .then((res: any) => {
            this.driveLicense = res;
        });
    }
    private objectSpanMethod(obj: any) {
       const { row, column, rowIndex, columnIndex } = obj;
       if (columnIndex !== 0) {
            const rowCount = this.spanArr[rowIndex];
            const colCount = rowCount > 0 ? 1 : 0;
            return {
                rowspan: rowCount,
                colspan: colCount,
            };
        } else {
            return {
                rowspan: 1,
                colspan: 1,
            };
        }
    }
    private getSpanArr(data: any) {
        const result = [];
        let pos = 0;
        for (let i = 0; i < data.length; i++) {
            if (i === 0) {
                result.push(1);
                pos = 0;
            } else {
                // 判断当前元素与上一个元素是否相同
                if (data[i].authCode === data[i - 1].authCode) {
                    result.push(0);
                    result[pos] += 1;
                } else {
                    result.push(1);
                    pos = i;
                }
            }
        }
        this.spanArr = result;
    }
    private downloadAuth(url: any) {
        this.$message.success('文件下载中，请稍等');
        window.open(url);
    }
    // 批量下载设备驱动与说明文档
    private batchDownDriveAndDoc() {
        const fileList: any = [];
        this.driveData.map((item: any) => {
            const data: any = {
                fileName: item.name,
                list: [],
            };
            if (item.fileUrl) {
                data.list.push({
                    fileUrl: item.fileUrl,
                    fileName: item.name + '-驱动.' + item.fileUrl.split('.')[1],
                });
            }
            if (item.wordUrl) {
                data.list.push({
                    fileUrl: item.wordUrl,
                    fileName: item.name + '-使用说明文档.' + item.wordUrl.split('.')[1],
                });
            }
            if (data.list.length) {
                fileList.push(data);
            }
        });
        batchDownload(fileList, `驱动和相关说明文档`);
    }
    // 生成驱动授权
    private showDriveAuth() {
        this.showDialog = true;
    }
    private CloseDriveAuth(num: number) {
        this.showDialog = false;
        if (num === 3) {
            this.getDriveLicense();
        }
    }
}

