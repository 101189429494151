
import '@/assets/css/iframeStyle.scss';
import { Component, Vue } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import ServiceItem from "../../resourceManage/buy/component/ServiceItem.vue";
import ServiceDialog from "../../resourceManage/buy/component/ServiceDialog.vue";
import ResetUserNumberDialog from "../../resourceManage/buy/component/ResetUserNumberDialog.vue";
import ProductItem from "./component/ProductItem.vue";
import ResetServerDialog from "./component/ResetServerDialog.vue";
import AddPurchaseDrawer from "./component/AddPurchaseDrawer.vue";
import AddDriveDrawer from "./component/AddDriveDrawer.vue";
import { moneyFormat } from '@/utils/utils';
import { RESOURCE_CONFIG, SERVER_CONFIG_MC_FOUR } from '@/utils/global';

@Component({
    name: "OrderConfirm",
    components: {
        ContentTitle,
        ServiceItem,
        ServiceDialog,
        ProductItem,
        AddPurchaseDrawer,
        ResetServerDialog,
        AddDriveDrawer,
        ResetUserNumberDialog,
    },
})
export default class OrderConfirm extends Vue {
    private contentTitle: string = "订单确认页";
    private serviceFlag: boolean = false;
    private resetServerFlag: boolean = false;
    private drawerFlag: boolean = false;
    private drawerDriveFlag: boolean = false; // 继续加购驱动
    private serviceListFlag: boolean = true;
    private serviceList: any = [];
    private serviceItemData: any = {};
    private productList: any = [];
    // param传过来的goodsList
    private goodsList: any = [];
    // 用来记录可以使用的goodsList，这上面的goodsList区分开始
    private goodsListRecord: any = [];
    private addGoods: number = 0; // 1，为产品详情过来的订单,让用户可以加购商品
    private userId: string = '';
    private driveLicenseApplyId: string = ''; // 驱动申请后，购买产品操作，保存驱动申请中的申请编号
    // 所有订单价格
    private allOrderTab: any = {
        discountMoney: 0,
        totalMoney: 0,
    };
    private loading: boolean = false;
    // 已选产品
    private selectedList: any = {
        goodsList: [],
    };
    // 服务器已选产品
    private selectedServerList: any = {
        goodsList: [],
    };
    private resetUserNumberFlag: boolean = false; // 调整云用户数配置
    private serverIdMoreDev: string = '-1';
    private serverFourList: any = []; // mc4.0的服务器列表
    // 4.0正式服务器列表
    private companyServerFourList: any = [];
    // 4.0试用服务器参数
    private companyServerFourTrialObj: any = {};
    // 服务器的授权用户数
    private userNumber: number = 0;

    private async created() {
        if (this.$route.fullPath.indexOf('goodsData=') > -1) {
            this.goodsList = JSON.parse(unescape(this.$route.query.goodsData + ''));
            console.log('this.goodsList', this.goodsList);
            if (this.goodsList && this.goodsList.length > 0) {
                this.goodsList.forEach((item: any) => {
                    // 订单中有驱动时，关闭服务列表
                    if (item.orderGoodsSceneType === RESOURCE_CONFIG.DRIVE_GOODSSCENETYPE) {
                        this.serviceListFlag = false;
                    }
                    if (item.mcVersion === '4.0') {
                        if (item.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE
                            && item.goodsId !== RESOURCE_CONFIG.MOREDEV_GOODSID
                            && item.serverId) {
                            this.serverIdMoreDev = item.serverId.toString();
                        } else {
                            this.serverIdMoreDev = '-1';
                        }
                    }
                });
            }
        }
        if (this.$route.fullPath.indexOf('addGoods=') > -1) {
            this.addGoods = Number(this.$route.query.addGoods);
        }
        if (this.$route.fullPath.indexOf('userData=') > -1) {
            this.userId = this.$route.query.userData + '';
        }
        if (this.$route.fullPath.indexOf('driveLicenseApplyId=') > -1) {
            this.driveLicenseApplyId = this.$route.query.driveLicenseApplyId + '';
        }
        const setInfo = localStorage.getItem('set_info_api');
        if (setInfo === null || setInfo === '' || setInfo === undefined) {
            this.getSetInfo();
        } else {
            const res = JSON.parse(localStorage.getItem('set_info_api') + '');
            res.userLimitList.filter((item: any) => {
                if (item.price === 0) {
                    this.userNumber = item.number;
                }
            });
        }
        this.getCompanyServer();
        await this.orderCreate();
        await this.getServiceList();
    }
    private getSetInfo() {
        // 得到系统商品相关的配置
        const paramData: any = {};
        paramData.preventRepeat = 'orderConfirm';
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/goods/set-info')
        .then((res: any) => {
            localStorage.setItem('set_info_api', JSON.stringify(res));
            // 得到免费服务器的授权用户数
            res.userLimitList.filter((item: any) => {
                if (item.price === 0) {
                    this.userNumber = item.number;
                }
            });
        });
    }
    // 得到服务商品列表
    private async getServiceList() {
        const paramData: any = {};
        paramData.pageNo = 1;
        paramData.pageSize = 200;
        if (this.userId !== '') {
            paramData.userList = unescape(this.userId).split(',');
        }
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/goods/service')
        .then((res: any) => {
            this.serviceList = [];
            res.list.forEach((item: any) => {
                item.serverNum = 1;
                this.serviceList.push(item);
            });
        });
    }
    // 创建订单数据
    private async orderCreate(commandFlag = false, com = '-1') {
        this.loading = true;
        const paramData: any = {};
        paramData.goodsList = [];
        console.log('this.goodsList', this.goodsList);
        const isNewMoreDev = this.goodsList.some((item: any) => (
            item.mcVersion === '4.0' && item.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE
        ));
        // 首次进来不进行组装数据，只有切换服务器的时候，才组装数据操作，因为会有切换新旧服务器需要做部分配置添加删除操作
        if (isNewMoreDev && commandFlag) {
            console.log('orderCreate isNewMoreDev');
            paramData.goodsList = this.assembleGoodsProductList(commandFlag, com);
        } else {
            console.log('orderCreate normal');
            paramData.goodsList = this.goodsList;
        }
        if (this.userId !== '') {
            paramData.userList = unescape(this.userId).split(',');
        }
        return new Promise((resolve, reject) => {
            this.$httpService.postData(paramData, '/apiProxy/api/frontend/orders/create', {}, 1)
            .then((res: any) => {
                this.productList = [];
                this.goodsListRecord = [];
                res.orderList.forEach((item: any) => {
                    this.productList.push(item);
                    item.detailList.forEach((detail: any) => {
                        this.goodsListRecord.push({
                            goodsId: detail.goodsId, // 选中商品传给后端的值
                            appName: detail.goodsTitle,
                            originalPrice: detail.orderDetailOriginalPrice,
                            orderDetailNumber: detail.orderDetailNumber, // 选中商品数量(累加)
                            orderDetailTimeNumber: detail.orderDetailTimeNumber,
                            orderGoodsSceneType: detail.orderGoodsSceneType,
                            dbType: detail.dbType,
                        });
                    });
                });
                this.allOrderTab = {
                    discountMoney: res.discountMoney,
                    totalMoney: res.totalMoney,
                };
                if (commandFlag) {
                    this.serverIdMoreDev = com;
                }
                this.goodsList = JSON.parse(JSON.stringify(paramData.goodsList));
                const driveLen =
                    this.goodsList
                    .filter((item: any) => item.orderGoodsSceneType === RESOURCE_CONFIG.DRIVE_GOODSSCENETYPE).length;
                // 如果是驱动，则使用上面的数据结构，如果不是则使用旧版
                if (driveLen === 0) {
                    this.goodsListRecord = [];
                    this.goodsListRecord = JSON.parse(JSON.stringify(this.goodsList));
                }
                this.loading = false;
                resolve(res);
            }).catch((err: any) => {
                this.loading = false;
                reject(err);
                // mc4.0产品，资源不足或者服务器过期另外提示
                if (this.isMoreDev
                    && (err.code === 3004 || err.code === 3005 || err.code === 3006 || err.code === 400013)) {
                    let msg = `云服务器"<span style="color:#409eff">${this.computedServerName}</span>"的配置过低，
                        如需部署新的应用，请先升级云服务器配置，或切换其他云服务器。`;
                    if (err.code === 400013) {
                        msg = `云服务器"<span style="color:#409eff">${this.computedServerName}</span>"已过期，
                            如需部署新的应用，请先续费云服务器。`;
                    }
                    this.$alert(msg, '提示', {
                        showClose: false,
                        confirmButtonText: '我知道了',
                        dangerouslyUseHTMLString: true,
                        callback: (action) => {
                            // ...
                        },
                    });
                } else if (err.code === 400001 || err.code === 400002 || err.code === 400003
                    || err.code === 400010 || err.code === 400011 || err.code === 400013) {
                    // buyFlag=1 没有提示的errCode提示
                    this.$alert(err.message, '提示', {
                        showClose: false,
                        confirmButtonText: '我知道了',
                        callback: (action) => {
                            // ...
                        },
                    });
                }
            });
        });
    }
    // 正常购买4.0产品的时候，组装请求数据。因为会有切换4.0云服务器的情况，切换新加服务器需要添加相关配，切换旧服务器反之剔除
    // 如果在组装数据的时候，传到当前页面已有相关云服务器、用户数、平台相关配置，优先使用传到当前页面的数据
    private assembleGoodsProductList(commandFlag = false, com = '-1') {
        const goodsList: any = [];
        this.goodsList.forEach((item: any) => {
            const obj = JSON.parse(JSON.stringify(item));
            if (commandFlag && com !== '-1'
                && obj.mcVersion === '4.0' && obj.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE) {
                obj.serverId = com;
            } else {
                delete obj.serverId;
            }
            if (
                obj.goodsId !== RESOURCE_CONFIG.SERVER_GOODSID
                && obj.goodsId !== RESOURCE_CONFIG.USERNUMBER_GOODSID
                && obj.goodsId !== RESOURCE_CONFIG.MOREDEV_GOODSID
            ) {
                goodsList.push(obj);
            }
        });
        // 封装一下，如果是有服务器配置的话，就使用以前的，如果没有切换到新开的话 就给一个默认的
        // 保留 4.0新开服务器相关数据 start
        let isNewMoreDev = false;
        let serverTimeNumber = 0;
        let serverDBType = 0;
        // end
        // 查看选中的是否有mc4.0产品
        console.log('this.companyServerFourObj', this.companyServerFourObj);
        this.goodsList.forEach((item: any) => {
            if (item.mcVersion === '4.0' && com === '-1') {
                isNewMoreDev = true;
                serverTimeNumber = item.serverTimeNumber;
                serverDBType = item.dbType;
            }
        });
        if (isNewMoreDev && com === '-1') {
            const isHasOracle = this.goodsList
                .some((el: any) => el.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID);
            // 当正式4.0服务器列表为空的时候，先得到4.0试用服务器中已试用的应用列表
            let userSysTypeLen = 0;
            if (this.companyServerFourList.length === 0 && this.companyServerFourTrialObj.userSysTypeInfoList) {
                const userSysTypeInfoList = this.companyServerFourTrialObj.userSysTypeInfoList;
                // 得到试用服务器应用列表中，不是关联安装商品的数量，这样计算会遗漏掉关联安装商品的数量
                // const isNotRelatedList =
                //     userSysTypeInfoList.filter((elItem: any) => elItem.isRelatedInstall === false);
                userSysTypeLen = userSysTypeInfoList.length;
            }
            console.log('isNotRelatedListLen', userSysTypeLen);
            if (this.companyServerFourList.length === 0 && this.companyServerFourTrialObj.userSysTypeInfoList) {
                this.goodsList.forEach((el: any) => {
                    if (el.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE) {
                        // 查看已拥有的应用，和当前购买的应用相同的有几个，这里当成是试用数量
                        // 如果goodsId一样 pg就转正算一个 oracle需要加一个数量
                        const userSysTypeInfoListLen = this.companyServerFourTrialObj.userSysTypeInfoList
                            .filter((elItem: any) => elItem.goodsId === el.goodsId).length;
                        userSysTypeLen -= userSysTypeInfoListLen;
                        if (isHasOracle && userSysTypeInfoListLen > 0) {
                            userSysTypeLen += 1;
                        }
                    }
                });
                // 查看mc4.0使用服务器里 是否有平台，平台不算应用，需要扣除相关数量
                const moreDevLen = this.companyServerFourTrialObj.userSysTypeInfoList
                    .filter((el: any) => el.goodsId === RESOURCE_CONFIG.MOREDEV_GOODSID).length;
                if (moreDevLen > 0) {
                    userSysTypeLen -= moreDevLen;
                }
                // 如果选择购买时是oracle数据库，因为试用服务器是pg数据库，这样转正就需要pg版和oracle版共存的
                if (isHasOracle && moreDevLen > 0) {
                    userSysTypeLen += moreDevLen;
                }
            }
            const goodsListFourApplicationLen = this.goodsList
                .filter((el: any) => (el.mcVersion === '4.0' )).length;
            // 查看已选中的产品 是否有 开发平台呢
            const hasMoreDev = this.goodsList.some((el: any) => el.goodsId === RESOURCE_CONFIG.MOREDEV_GOODSID);
            // 20230509还有一个平台的数量
            let hasAppFOurLen = goodsListFourApplicationLen + userSysTypeLen;
            // 这里1，如果第一次添加需要添加1，如果不是第一次就已经在goodsListFourApplicationLen里面了
            if (!hasMoreDev) {
                hasAppFOurLen += 1;
            }
            // 计算所有已选产品，包含了多少个关联安装商品
            let installRelatedLenTotal = 0;
            goodsList.forEach((el: any) => {
                if (el.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE) {
                    installRelatedLenTotal += el.installRelatedLen || 0;
                }
            });
            console.log('goodsList', goodsList);
            console.log('installRelatedLenTotal', installRelatedLenTotal);
            hasAppFOurLen += installRelatedLenTotal;
            console.log('hasAppFOurLen', hasAppFOurLen);
            // 当转正的时候，有部分试用服务器中的应用要一起算到正式里面，占一个数量
            // 通过数量来计算给多少服务器配置
            // 理论上4.0，没有产品，这里先做一次适配
            let serverCpu = 0;
            let serverMemory = 0;
            let serverHardDisk = 0;
            if (hasAppFOurLen <= 2) {
                serverCpu = SERVER_CONFIG_MC_FOUR.FOUR_VCUP;
                serverMemory = SERVER_CONFIG_MC_FOUR.FOUR_VCUP_MEMORY;
                serverHardDisk = SERVER_CONFIG_MC_FOUR.FOUR_VCUP_HARDDISK;
            } else if (hasAppFOurLen > 2 && hasAppFOurLen <= 4) {
                serverCpu = SERVER_CONFIG_MC_FOUR.SIX_VCUP;
                serverMemory = SERVER_CONFIG_MC_FOUR.SIX_VCUP_MEMORY;
                serverHardDisk = SERVER_CONFIG_MC_FOUR.SIX_VCUP_HARDDISK;
            } else if (hasAppFOurLen > 4 && hasAppFOurLen <= 6) {
                serverCpu = SERVER_CONFIG_MC_FOUR.EIGHT_VCUP;
                serverMemory = SERVER_CONFIG_MC_FOUR.EIGHT_VCUP_MEMORY;
                serverHardDisk = SERVER_CONFIG_MC_FOUR.EIGHT_VCUP_HARDDISK;
            } else {
                serverCpu = SERVER_CONFIG_MC_FOUR.OTHER_VCUP;
                serverMemory = SERVER_CONFIG_MC_FOUR.OTHER_VCUP_MEMORY;
                serverHardDisk = SERVER_CONFIG_MC_FOUR.OTHER_VCUP_HARDDISK;
            }
            const serverArr = this.goodsList.filter((el: any) => el.goodsId === RESOURCE_CONFIG.SERVER_GOODSID);
            if (serverArr.length > 0) {
                goodsList.push(serverArr[0]);
            } else {
                const serverObj = {
                    goodsId: RESOURCE_CONFIG.SERVER_GOODSID, // 选中商品传给后端的值
                    appName: '云服务器',
                    orderDetailNumber: 1, // 选中商品数量(固定不变的)
                    orderDetailTimeNumber: serverTimeNumber, // 时长
                    cpu: serverCpu,
                    memory: serverMemory,
                    harddisk: serverHardDisk,
                    orderGoodsSceneType: RESOURCE_CONFIG.SERVER_GOODSSCENETYPE,
                    dbType: serverDBType,
                };
                goodsList.push(serverObj);
            }
            const userNumberArr = this.goodsList.filter((el: any) => el.goodsId === RESOURCE_CONFIG.USERNUMBER_GOODSID);
            if (userNumberArr.length > 0) {
                goodsList.push(userNumberArr[0]);
            } else {
                const userNumberObj = {
                    goodsId: RESOURCE_CONFIG.USERNUMBER_GOODSID, // 选中商品传给后端的值
                    appName: '云用户数',
                    orderDetailNumber: 1, // 选中商品数量(固定不变的)
                    orderDetailTimeNumber: serverTimeNumber, // 时长
                    orderGoodsSceneType: RESOURCE_CONFIG.USERNUMBER_GOODSSCENETYPE,
                    userNumber: this.userNumber,
                };
                goodsList.push(userNumberObj);
            }
            const moreDevArr = this.goodsList.filter((el: any) => el.goodsId === RESOURCE_CONFIG.MOREDEV_GOODSID);
            if (moreDevArr.length > 0) {
                goodsList.push(moreDevArr[0]);
            } else {
                // 新开4.0产品需要添加一个 开发平台
                const moreDevGoods: any = {
                    appName: 'MoreDev 低代码开发平台',
                    goodsId: RESOURCE_CONFIG.MOREDEV_GOODSID, // 选中商品传给后端的值
                    skuId: process.env.VUE_APP_URL === 'https://cloud.morewis.com'
                            ? RESOURCE_CONFIG.MOREDEV_SKUID
                            : undefined, // 开发平台 生产环境中有规格
                    orderDetailNumber: 1, // 选中商品数量(固定不变的)
                    orderDetailTimeNumber: serverTimeNumber, // 时长 跟云服务器时长保持一致
                    orderGoodsSceneType: RESOURCE_CONFIG.APP_GOODSSCENETYPE,
                    dbType: serverDBType,
                    installRelatedLen: 0,
                    mcVersion: '4.0',
                };
                goodsList.push(moreDevGoods);
            }
        }
        return goodsList;
    }
    // 接收子页面传回来的值
    private propGoBlack() {
        window.history.back();
    }
    private propBuyService(value: object) {
        this.serviceItemData = value;
        this.serviceFlag = true;
    }
    private propCloseMsg(value: any) {
        this.serviceFlag = false;
        if (!value.id) {
            return;
        }
        // 添加服务前，先删除
        this.goodsList.forEach((item: any, index: number) => {
            if (item.goodsId === value.id) {
                this.goodsList.splice(index, 1);
            }
        });
        const itemData: any = {
            appName: value.name,
            goodsId: value.id, // 选中商品传给后端的值
            orderDetailNumber: 1, // 选中商品数量(固定不变的)
            orderDetailTimeNumber: value.serverNum, // 时长
            orderGoodsSceneType: RESOURCE_CONFIG.SERVICE_GOODSSCENETYPE,
        };
        this.goodsList.push(itemData);
        this.orderCreate();
    }
    private propRemoveProduct(productItem: any) {
        // 服务器续费时，移除数据库续费
        if (this.judgeServerRenew()
            && productItem.orderGoodsSceneType === RESOURCE_CONFIG.ORACLE_GOODSSCENETYPE_RENEW) {
            this.removeDbRenew(productItem);
            return;
        }
        if (productItem.goodsId === RESOURCE_CONFIG.SERVER_GOODSID) {
            this.startResetServer();
            return;
        }
        if (productItem.goodsId === RESOURCE_CONFIG.USERNUMBER_GOODSID) {
            this.startResetUserNumber();
            return;
        }
        const productList: any = [];
        this.goodsList.forEach((item: any) => {
            if (item.goodsId
                && item.goodsId !== RESOURCE_CONFIG.ORACLE_GOODSID
                && item.goodsId !== RESOURCE_CONFIG.USERNUMBER_GOODSID
                && item.goodsId !== RESOURCE_CONFIG.SERVER_GOODSID
                && item.goodsId !== RESOURCE_CONFIG.MOREDEV_GOODSID) {
                productList.push(item);
            } else if (item.goodsId === RESOURCE_CONFIG.SERVER_GOODSID
                && item.orderGoodsSceneType !== RESOURCE_CONFIG.SERVER_GOODSSCENETYPE) {
                productList.push(item);
            }
        });
        const hasFourAppLen = productList.filter((el: any) =>
            (
                el.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE
            &&
                el.mcVersion === '4.0'
            &&
                el.goodsId !== RESOURCE_CONFIG.MOREDEV_GOODSID
            )).length;
        const hasNotAppLen = productList.filter((el: any) =>
            (
                el.orderGoodsSceneType === RESOURCE_CONFIG.SERVICE_GOODSSCENETYPE
            ||
                el.orderGoodsSceneType === RESOURCE_CONFIG.SAAS_GOODSSCENETYPE
            ||
                el.orderGoodsSceneType === RESOURCE_CONFIG.SAAS_GOODSSCENETYPE_RENEW
            )).length;
        // 当已选中的产品没有4.0应用，开发平台 和 服务或者saas 一起购买时，开发平台也算一个产品
        if (hasFourAppLen === 0 && hasNotAppLen > 0) {
            this.goodsList.forEach((item: any) => {
                if (item.goodsId === RESOURCE_CONFIG.MOREDEV_GOODSID) {
                    productList.push(item);
                }
            });
        }
        const goodsListServerLen = this.goodsList
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.SERVER_GOODSID).length;
        const goodsListApplicationLen = this.goodsList
                .filter((el: any) => (
                    el.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE
                    &&
                    el.goodsId !== RESOURCE_CONFIG.MOREDEV_GOODSID
                )).length;
        // 有服务器时，当只剩下一个应用，操作移除应用时
        if (productItem.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE
            && goodsListServerLen > 0 && goodsListApplicationLen === 1) {
            this.$message.warning('最后一个应用商品，暂不支持移除！');
            return;
        }
        if (productList.length <= 1) {
            this.$message.warning('当前仅剩一件商品不支持继续移除！');
        } else {
            this.$confirm(`请确定是否要移除"${productItem.goodsTitle}"产品?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                // 确定
                let isApp = false;
                this.goodsList.forEach((item: any, index: number) => {
                    if (item.goodsId === productItem.goodsId) {
                        this.goodsList.splice(index, 1);
                        if (item.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE) {
                            isApp = true;
                        }
                    }
                });
                if (isApp) {
                    this.goodsList.forEach((item: any, index: number) => {
                        if (item.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID) {
                            if (item.orderDetailNumber <= 1) {
                                this.goodsList.splice(index, 1);
                            } else {
                                item.orderDetailNumber -= 1;
                            }
                        }
                    });
                }
                this.orderCreate();
            }).catch(() => {
                // 取消
            });
        }
    }
    // 服务器续费时，移除数据库续费
    private removeDbRenew(productItem: any) {
        this.$confirm(`请确定是否要移除"${productItem.goodsTitle}"?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
            // 确定
            this.goodsList.forEach((item: any, index: number) => {
                if (item.goodsId === productItem.goodsId) {
                    this.goodsList.splice(index, 1);
                }
            });
            this.orderCreate();
        }).catch(() => {
            // 取消
        });
    }
    private propAddDrive(productItem: any) {
        if (productItem.goodsPriceType === RESOURCE_CONFIG.DRIVE_GOODSPRICETYPE) {
            this.startAddDrive();
            return;
        }
    }
    // 开始继续添加驱动
    private startAddDrive() {
        this.selectedList.goodsList = [];
        this.goodsListRecord.forEach((item: any, index: number) => {
            this.selectedList.goodsList.push(item);
        });
        this.drawerDriveFlag = true;
    }
    private startResetServer() {
        this.selectedServerList.goodsList = [];
        this.goodsListRecord.forEach((item: any, index: number) => {
            if (item.orderGoodsSceneType !== 24) {
                this.selectedServerList.goodsList.push(item);
            }
        });
        this.resetServerFlag = true;
    }
    private startResetUserNumber() {
        this.selectedServerList.goodsList = [];
        this.goodsListRecord.forEach((item: any, index: number) => {
            if (item.orderGoodsSceneType !== 24) {
                this.selectedServerList.goodsList.push(item);
            }
        });
        this.resetUserNumberFlag = true;
    }
    private propResetCloseMsg(value: any) {
        if (value && !value.goodsId) {
            this.resetServerFlag = false;
            return;
        }
        // 添加服务器前，先删除
        this.goodsList.forEach((item: any, index: number) => {
            if (item.goodsId === RESOURCE_CONFIG.SERVER_GOODSID) {
                this.goodsList.splice(index, 1);
            }
        });
        this.goodsList.push(value);
        this.orderCreate();
        this.resetServerFlag = false;
    }
    // 调整云用户数配置的回调
    private propResetUserNumberCloseMsg(value: any) {
        if (value && !value.goodsId) {
            this.resetUserNumberFlag = false;
            return;
        }
        // 添加云用户数前，先删除
        this.goodsList.forEach((item: any, index: number) => {
            if (item.goodsId === RESOURCE_CONFIG.USERNUMBER_GOODSID) {
                this.goodsList.splice(index, 1);
            }
        });
        this.goodsList.push(value);
        this.orderCreate();
        this.resetUserNumberFlag = false;
    }
    private openAddPurchase() {
        this.selectedList.goodsList = [];
        this.goodsListRecord.forEach((item: any, index: number) => {
            if (item.orderGoodsSceneType !== 24) {
                this.selectedList.goodsList.push(item);
            }
        });
        this.drawerFlag = true;
    }
    private propConfirmAdd(productTab: any) {
        let oracleNum = 0;
        let oracleTab = {};
        let isOracle = false;
        productTab.forEach((item: any) => {
            if (item.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID) {
                oracleNum = item.orderDetailNumber;
                oracleTab = item;
                isOracle = true;
            } else {
                const goodsIdLen = this.goodsList
                .filter((el: any) => el.goodsId === item.goodsId).length;
                if (goodsIdLen > 0) { // 替换操作
                    this.goodsList.forEach((el: any) => {
                        if (el.goodsId === item.goodsId) {
                            el.orderDetailNumber = item.orderDetailNumber;
                        }
                    });
                } else {
                    this.goodsList.push(item);
                }
            }
        });
        this.goodsList.forEach((item: any) => {
            if (item.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID) {
                item.orderDetailNumber += oracleNum;
            }
        });
        const goodsListOracleLen = this.goodsList
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID).length;
        if (isOracle && goodsListOracleLen === 0) {
            this.goodsList.push(oracleTab);
        }
        this.orderCreate();
        this.drawerFlag = false;
    }
    private propConfirmAddDrive(productTab: any) {
        if (productTab.length === 0) {
            return;
        }
        this.goodsList = [].concat(productTab);
        this.orderCreate();
        this.drawerDriveFlag = false;
    }
    // 金额格式化
    private moneyFormat(value: any) {
        return moneyFormat(value, 2);
    }
    private handleClose(done: any) {
        this.drawerFlag = false;
    }
    private handleDriveClose(done: any) {
        this.drawerDriveFlag = false;
    }
    private saveOrder() {
        // 生成订单
        const paramData: any = {};
        paramData.goodsList = this.goodsListRecord;
        if (this.userId !== '') {
            paramData.userList = unescape(this.userId).split(',');
        }
        if (this.driveLicenseApplyId !== '') {
            paramData.extension = {};
            paramData.extension.driveLicenseApplyId = this.driveLicenseApplyId;
        }
        console.log('saveOrder paramData', paramData);
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/orders/save')
        .then((res: any) => {
            if (res.money <= 0) {
                this.$router
                .push(`/company/redirectPage?url=/user/order/paySuccess.htm?orderIds=${res.orderIds},-1`)
                .catch((err) => err);
            } else {
                this.$router
                .push(`/company/redirectPage?url=/user/goods/order/payGoodsOrder.htm?orderIds=${res.orderIds}`)
                .catch((err) => err);
            }
        }).catch((err: any) => {
            if (err.code === 20051) {
                this.$confirm(`您有初次购买4.0云服务器订单尚未支付，请前往操作后再进行其它相关操作。`, '提示', {
                    confirmButtonText: '立即前往',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    // 确定
                    this.$router.push({ path: "/company/myOrder", query: {orderStatus: '0'}});
                }).catch(() => {
                    // 取消
                });
            }
        });
    }
    private removeDecide(productItem: any) {
        let productLen = 0;
        let serverLen = 0;
        let mcLen = 0;
        this.productList.forEach((itemTab: any) => {
            productLen += itemTab.detailList.filter((item: any) =>
                item.goodsId !== RESOURCE_CONFIG.SERVER_GOODSID
                && item.goodsId !== RESOURCE_CONFIG.USERNUMBER_GOODSID
                && item.goodsId !== RESOURCE_CONFIG.ORACLE_GOODSID,
            ).length;
            serverLen += itemTab.detailList.filter((item: any) =>
                item.goodsId === RESOURCE_CONFIG.SERVER_GOODSID,
            ).length;
            // 统计 应用 服务 saas的总数，如果只有一个的话，不给移除
            mcLen += itemTab.detailList.filter((item: any) =>
                item.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE
                || item.goodsPriceType === RESOURCE_CONFIG.SAAS_GOODSPRICETYPE
                || item.goodsPriceType === RESOURCE_CONFIG.SERVICE_GOODSPRICETYPE,
            ).length;
        });
        const currentMcLen = productItem.detailList.filter((item: any) =>
            item.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE,
        ).length;
        // 当有服务器，只有一个应用，当前产品就是应用就不显示移除
        if (serverLen === 1 && currentMcLen === 1 && mcLen === 1) {
            return false;
        }
        // 当多个产品是就显示移除按钮
        if (productLen > 1) {
            return true;
        } else {
            return false;
        }
    }
    private judgeRenew() {
        const renewLen = this.goodsList
            .filter((item: any) => {
                return item.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE_RENEW
                    || item.orderGoodsSceneType === RESOURCE_CONFIG.SERVER_GOODSSCENETYPE_RENEW
                    || item.orderGoodsSceneType === RESOURCE_CONFIG.ORACLE_GOODSSCENETYPE_RENEW;
            }).length;
        return renewLen > 0 ? true : false;
    }
    // 判断是否是 服务续费
    private judgeServerRenew() {
        const renewLen = this.goodsList
            .filter((item: any) => {
                return item.orderGoodsSceneType === RESOURCE_CONFIG.SERVER_GOODSSCENETYPE_RENEW;
            }).length;
        return renewLen > 0 ? true : false;
    }
    private isBuyCompletely() {
        const grantLen = this.goodsList
            .filter((item: any) => item.goodsId === RESOURCE_CONFIG.GRANT_GOODSID)
            .length;
        return grantLen > 0 ? true : false;
    }
    private propSelectItemTime(productItem: any) {
        // 替换时长
        this.goodsList.forEach((item: any, index: number) => {
            if (item.goodsId === productItem.goodsId) {
                item.orderDetailTimeNumber = productItem.orderDetailTimeNumber;
            }
        });
        this.orderCreate();
    }
    // 获得个人的 服务器列表
    private getCompanyServer() {
        const paramUserId = this.userId !== '' ? this.userId : this.$store.state.userInfo.id;
        this.$httpService
        .getData({preventRepeat: 'orderConfirm'}, '/apiProxy/api/frontend/users/company-server/' + paramUserId)
        .then((res: any) => {
            this.serverFourList = [];
            res.serverInfoList.forEach((item: any) => {
                if (item.serverType === SERVER_CONFIG_MC_FOUR.SERVER_TYPE) {
                    item.serverId = item.serverId.toString();
                    this.serverFourList.push(item);
                }
            });
            const selectMoreDev = this.goodsList.some((el: any) => el.mcVersion === '4.0');
            if (!selectMoreDev && this.serverFourList.length > 0 && this.serverIdMoreDev.toString() === '-1') {
                this.serverIdMoreDev = this.serverFourList[0].serverId;
            }
            // 4.0正式
            this.companyServerFourList = [];
            res.serverInfoList.forEach((item: any) => {
                if (item.serverType === SERVER_CONFIG_MC_FOUR.SERVER_TYPE) {
                    this.companyServerFourList.push(item);
                }
            });
            const objMCFourTrial = res.serverInfoList
                    .find((item: any) => item.serverType  === SERVER_CONFIG_MC_FOUR.SERVER_TYPE_TRIAL);
            this.companyServerFourTrialObj = Object.assign({}, objMCFourTrial);
        });
    }
    get isMoreDev() {
        // 正常（续费升级特殊的时候不计算正常范围）购买4.0应用时，才能切换服务器，过滤单独mc4.0平台的情况
        const isFourMCVersion = this.goodsList.some((item: any) =>
        (
            item.mcVersion === '4.0'
            && item.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE
            && item.goodsId !== RESOURCE_CONFIG.MOREDEV_GOODSID
        ));
        // 当不是4.0应用，并且不是应用的情况下，只有saas
        const isNotApp = this.goodsList.some((el: any) =>
        (
            el.mcVersion !== '4.0'
            && el.orderGoodsSceneType !== RESOURCE_CONFIG.APP_GOODSSCENETYPE
            && el.goodsId !== RESOURCE_CONFIG.SERVER_GOODSID
            && el.goodsId !== RESOURCE_CONFIG.USERNUMBER_GOODSID
            && el.goodsId !== RESOURCE_CONFIG.ORACLE_GOODSID
            && el.goodsId !== RESOURCE_CONFIG.GRANT_GOODSID
            && el.goodsId !== RESOURCE_CONFIG.MOREDEV_GOODSID
        ));
        // return isFourMCVersion || (isNotApp && this.addGoods === 1);
        return isFourMCVersion;
    }
    get computedServerName() {
        const tab = this.serverFourList.find((el: any) => el.serverId.toString() === this.serverIdMoreDev.toString());
        if (this.serverIdMoreDev.toString() === '-1') {
            return '新开云服务器';
        }
        return tab && tab.serverName ? tab.serverName : '请选择服务器';
    }
    get companyServerFourObj() {
        const tab = this.serverFourList
            .find((el: any) => el.serverId.toString() === this.serverIdMoreDev.toString());
        return tab || {
            serverId: '-1',
        };
    }
    // 切换4.0云服务器
    private handleCommand(com: string) {
        if (this.serverIdMoreDev.toString() !== com) {
            // 先设置serverIdMoreDev，如果报错再还原
            const serverIdMoreDevPrev = this.serverIdMoreDev;
            this.serverIdMoreDev = com;
            this.orderCreate(true, com).then(() => {
                this.serverIdMoreDev = com;
            }).catch(() => {
                this.serverIdMoreDev = serverIdMoreDevPrev;
            });
        }
    }
}
