
function checkTime(i: any) {
    if (i < 10) {
        i = `0${i}`;
    }
    return i;
}
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { toCode } from '@/utils/encrypt';
import { assembleOrderItemBuyout, isBuyCompletely } from '@/utils/assemble';

@Component({
  name: 'OrderItem',
})

export default class OrderItem extends Vue {
    @Prop({
        type: Object,
        default: {},
    }) private data!: any; // 接收父组件传过来的值

    @Prop({
        type: String,
        default: "",
    }) private orderType!: string;

    @Prop({
        type: Function,
        default: () => '',
    }) private moneyFormat!: any;

    private baseUrl = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private setTime: any = null;
    private nowTime: number = 0;
    private countDownTime: string = '';
    private checkboxDis: boolean = false;
    private payBtn: boolean = false;
    private invoiceBtn: boolean = false;
    private upProofBtn: boolean = false;
    private ckProofBtn: boolean = false;
    private detailBtn: boolean = false;
    private cancelBtn: boolean = false;
    private evaluateBtn: boolean = false;
    private hasDrive: number = 0;
    private haveOrderContract: boolean = false;
    private applyGrantBtn: boolean = false;
    private selectProjectBtn: boolean = false;

    // 给父组件传值
    // 立即支付
    @Emit('allUploadProof')
    private allUploadProof() {
        return this.data;
    }
    // 申请开票
    @Emit('askforInvoice')
    private askforInvoice() {
        return this.data;
    }
    // 查看凭证
    @Emit('proofClick')
    private proofClick(status: number) {
        return this.data;
    }
    // 查看详情
    @Emit('gotoDeatil')
    private gotoDeatil() {
        return this.data;
    }
    // 查看详情
    @Emit('downloadContract')
    private downloadContract() {
        return this.data;
    }
    // 取消订单
    @Emit('cancelOrder')
    private cancelOrder() {
        return this.data;
    }
    // 立即评价
    @Emit('gotoEvaluate')
    private gotoEvaluate() {
        return this.data;
    }
    // 立即评价
    @Emit('gotoDriveAuthorize')
    private gotoDriveAuthorize() {
        return this.data;
    }

    @Watch('data')
    private dataChange(value: any) {
        clearInterval(this.setTime);
        this.init();
    }

    private created() {
        this.init();
    }
    private init() {
        if (isBuyCompletely(this.data.orderGoodsDetailList)) {
            // 买断产品和授权用户数 上下显示
            const detailListCopy: any = assembleOrderItemBuyout(this.data.orderGoodsDetailList);
            this.data.orderGoodsDetailList = [].concat(detailListCopy);
        }
        this.countDownTime = '';
        const data: any = JSON.parse(JSON.stringify(this.data));
        this.nowTime = new Date().getTime();
        if (data && data.id) {
            if (data.orderStatus === 0 && data.autoPayTimeOutTime) {
                this.setTime = setInterval(() => {
                    this.showCountDown(data, this.nowTime);
                }, 1000);
            }
            this.setCheckboxDis();
            this.setOperBtn();
        }
    }

    private destroyed() {
        clearInterval(this.setTime);
    }

    private showCountDown(data: any, nowTime: number) {
        this.nowTime = new Date(nowTime).getTime() + 1000;
        const leftTime = new Date(data.autoPayTimeOutTime).getTime() - this.nowTime;
        let leftSecond = Math.floor(leftTime) / 1000;
        if (leftSecond > 1) {
            leftSecond = leftSecond - 1;
            const second = checkTime(Math.floor(leftSecond % 60));
            const minite = checkTime(Math.floor((leftSecond / 60) % 60));
            const hour = checkTime(Math.floor((leftSecond / 60 / 60) % 24));
            const day = checkTime(Math.floor(leftSecond / 60 / 60 / 24));
            if (second < 0 && minite < 0 && hour < 0) {
                clearInterval(this.setTime);
            } else if (hour === '0NaN' || minite === '0NaN' || second === '0NaN') {
                clearInterval(this.setTime);
            } else {
                if (day > 0) {
                    this.countDownTime = `支付剩余时间：${day}天 ${hour}:${minite}:${second}`;
                } else {
                    this.countDownTime = `支付剩余时间：${hour}:${minite}:${second}`;
                }
            }
        } else {
            // this.countDownTime = `支付超时`;
        }
    }

    private setCheckboxDis() {
        const {orderStatus, autoPayTimeOutTime, invoiceStatus, money, payStyle, orderFirstOpenIdentity
        , orderCreateType, orderGoodsGroups} = this.data;
        // 交易完成；并且订单金额大于0.001的待开票订单；
        if (this.orderType === '2' && orderStatus === 3 && money > 0.001 && invoiceStatus === 0) {
            this.checkboxDis = false;
        } else if (this.orderType === '1' && orderStatus === 0  // 待支付
            && ((new Date(autoPayTimeOutTime).getTime() > this.nowTime && payStyle !== 1)   //  支付未超时；线上支付订单
            && !orderFirstOpenIdentity    // 初次开通服务
            && !orderGoodsGroups    // 合并订单
            && (!orderCreateType || orderCreateType !== 3))) {    // 试用转正订单
            this.checkboxDis = false;
        } else {
            this.checkboxDis = true;
        }
    }

    private setOperBtn() {
        this.payBtn = false;
        this.cancelBtn = false;
        this.ckProofBtn = false;
        this.upProofBtn = false;
        this.invoiceBtn = false;
        this.evaluateBtn = false;
        this.hasDrive = 0;
        this.haveOrderContract = false;
        const {orderStatus, autoPayTimeOutTime, payStyle, oldOrderId, invoiceStatus,
            evaluateStatus, money, payOfflineVoucherList, haveOrderContract, hasDrive} = this.data;

        if (orderStatus === 0) {
            this.cancelBtn = true;  // 取消订单
            if (new Date(autoPayTimeOutTime).getTime() > this.nowTime) {    // 支付未超时
                if (payStyle === 1) {   // 线下支付
                    if (payOfflineVoucherList != null && payOfflineVoucherList.length > 0) {
                        // 查看凭证
                        this.ckProofBtn = true;
                    } else {
                        this.upProofBtn = true; // 上传凭证
                    }
                } else {    // 线上支付
                    this.payBtn = true; // 立即支付
                }
            }
        }
        if (orderStatus === 3 && money > 0.001 && invoiceStatus === 0) {    // 订单金额大于0.001；并且是已完成的待开票订单；
            this.invoiceBtn = true; // 申请开票
        }
        if (payOfflineVoucherList != null && payOfflineVoucherList.length > 0) {
            // 查看凭证
            this.ckProofBtn = true;
        }
        if (orderStatus === 3 && evaluateStatus === 0) {    // 已完成待评价；
            this.evaluateBtn = true;
        }
        if (orderStatus === 3 || orderStatus === 4) {   // 已完成 || 部分支付完成
            this.hasDrive = hasDrive;
        }
        this.haveOrderContract = haveOrderContract;
        // 申请授权和查看项目
        if (orderStatus === 3 || orderStatus === 4) {
            this.applyGrantBtn = this.data.withBuyoutLicense;
            this.selectProjectBtn = this.data.associatedMcProjectId;
        }
    }
    private gotoApplyGrant() {
        const paramData: any = {};
        paramData.preventRepeat = 1;
        paramData.notLoading = true;
        this.$httpService.getData(paramData, `/apiProxy/api/frontend/project/grant/associated-order/${this.data.id}/apply-info`)
        .then((res: any) => {
            this.$router.push(`/company/projectList?active=2&orderId=${toCode(this.data.id + '')}`)
            .catch((err) => err);
        });
    }
    private gotoSelectProject() {
        // this.$router.push(`/company/projectList?orderNo=${this.data.orderNo}`)
        // .catch((err) => err);
        this.$router.push({
            name: 'projectList',
            query: {
                xId: toCode(this.data.associatedMcProjectId + ''),
            },
        });

    }
}
