import JSZip from 'jszip';
import FileSaver from 'file-saver';
// import axios from 'axios';
import { useMessage } from '@/utils/message';

const getFile = (url: string) => {
    return new Promise(async (resolve, reject) => {
        const response = await fetch(process.env.VUE_APP_URL + url);
        const blob = await response.blob();
        resolve(blob);
        // axios({
        //     method: 'GET',
        //     url: process.env.VUE_APP_URL + url,
        //     responseType: 'blob',
        // }).then((res: any) => {
        //     const blob = new Blob([res.data]);
        //     console.log(blob);
        //     resolve(blob);
        // }).catch((error: any) => {
        //     reject(error);
        // });
    });
};

const packFilePromise = (data: any, allZip: any) => {
    const zip = new JSZip();
    const promises: any[] = [];  // 用于存储多个promise
    data.list.forEach((item: any) => {
        const promise = getFile(item.fileUrl).then((res: any) => {
            const itemFileName = item.fileName || item.fileUrl;
            zip.file(itemFileName, res, {binary: true});
        });
        promises.push(promise);
    });
    const allPromise = Promise.all(promises).then(async () => {
        const res = await zip.generateAsync({
            type: "blob",
            compression: "DEFLATE",  // STORE：默认不压缩 DEFLATE：需要压缩
            compressionOptions: {
                level: 9,               // 压缩等级1~9    1压缩速度最快，9最优压缩方式
            },
        });
        allZip.file(`${data.fileName}.zip`, res, {binary: true});
    });
    return allPromise;
};
/**
 * 打包压缩下载
 * @param arr  源文件数组
 * @param fileName  压缩文件的名称
 */
const batchDownload = (arr: any[], fileName ?: string) => {
    if (arr.length <= 0) {
        useMessage(3, '当前无下载内容');
        return;
    }
    useMessage(1, '文件下载中，请稍等...', 0);
    const allZip = new JSZip();
    const allPromises: any[] = [];  // 用于存储多个promise
    arr.forEach((data: any) => {
        if (data.list) {
            const allPromise = packFilePromise(data, allZip);
            allPromises.push(allPromise);
        } else {
            const allPromise = getFile(data.fileUrl).then((res: any) => {
                const itemFileName = data.fileName || data.fileUrl;
                allZip.file(itemFileName, res, {binary: true});
            });
            allPromises.push(allPromise);
        }
    });
    Promise.all(allPromises).then(() => {
        allZip.generateAsync({
            type: "blob",
            compression: "DEFLATE",  // STORE：默认不压缩 DEFLATE：需要压缩
            compressionOptions: {
                level: 9,               // 压缩等级1~9    1压缩速度最快，9最优压缩方式
            },
        }).then((res: any) => {
            FileSaver.saveAs(res, fileName ? fileName : "压缩包.zip"); // 利用file-saver保存文件
            useMessage(0);
        }).catch(() => {
            useMessage(0);
        });
    });
};

export default batchDownload;

//  获取内部每个文件的file
export function loadAsyncFile(file: File) {
    const zip = new JSZip();
    return zip.loadAsync(file);
}

// 解析获取MC4.0包数据
export function readMC4File(file: File) {
    return new Promise(async (resolve, reject) => {
        loadAsyncFile(file).then(async (res: any) => {
            const result: any = [];
            const nameArr = Object.keys(res.files);
            for (const key of nameArr) {
                const arr = key.split('.');
                const path = arr[arr.length - 1];
                if (path === 'json') {
                    result[key] = await res.file(key).async('string');
                }
            }
            resolve(result);
        }).catch((err: any) => {
            reject(err);
        });
    });
}
