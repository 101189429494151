
import { ElForm } from 'node_modules/element-ui/types/form';
import { Component, Vue, Prop, Watch, Emit  } from 'vue-property-decorator';
import { subDate } from '@/utils/utils';
import RegisterFileCoc from './RegisterFileDoc.vue';

@Component({
    name: "CreateDriveAuth",
    components: {
        RegisterFileCoc,
    },
})
export default class CreateDriveAuth extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    }) private exitFlag!: boolean; // 接收父组件传过来的值
    @Prop({
        type: Object,
        default: {},
    }) private orderInfo!: any;
    @Prop(String) private expirationDate!: any;

    private showDialog: boolean = false; // 弹窗显示与关闭
    private formData: any = {
        fileUrl: '',
        fileName: '',
        expirationDate: '',
        expirationDateForever: '',
    };
    private caption: string = "生成驱动授权";
    private RegisterFileFlag: boolean = false;  // 注册文件获取方法
    private expirationDateForever = '3000-01-01 00:00:00';
    private submitLoading: boolean = false;
    private rules: any = {
        fileUrl: [
            { required: true, message: '请上传注册文件', trigger: 'change' },
        ],
    };
    get pickerOptions() {
        return {
            disabledDate: (val: any) => {
                if (this.expirationDate) {
                    return val > new Date(subDate(new Date(), 1, 'Y')) || val <= new Date(this.expirationDate);
                }
                return val > new Date(subDate(new Date(), 1, 'Y'));
            },
        };
    }
    @Watch('exitFlag') // 监听父组件传过来的值
    private changeFlagFun(value: boolean) {
        this.showDialog = value;
    }

    // 给父组件传值
    @Emit('infoCloseMsg')
    private closeTodo(num: number): number {
        return num;
    }
    //  确定按钮
    private closeDialog(num: number, submitName?: string) {
        if (num === 3) {
            // 判断校验是否通过
            if (submitName !== undefined) {
                const elForm = this.$refs[submitName]! as ElForm;
                elForm.validate((valid) => {
                    if (valid) {
                        this.submitLoading = true;
                        const paramData: any = Object.assign({}, this.formData);
                        paramData.expirationDate =
                            paramData.expirationDateForever === 1
                            ? null
                            : paramData.expirationDate + ' 00:00:00';
                        delete paramData.expirationDateForever;
                        delete paramData.fileName;
                        console.log(paramData);
                        this.$httpService.postData(paramData, `/apiProxy/api/frontend/orders/${this.orderInfo.id}/drive/license`)
                        .then((res: any) => {
                            this.submitLoading = false;
                            this.showDialog = false;
                            this.closeTodo(num); // 给父组件传值
                            this.$message({
                                message: '生成驱动成功！',
                                type: 'success',
                            });
                        }).catch(() => {
                            this.submitLoading = false;
                        });
                    } else {
                        return false;
                    }
                });
            }
        } else {
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        }
    }
    // 弹窗关闭后清除所有数据
    private afterClose() {
        const elForm = this.$refs.formRef! as ElForm;
        elForm.resetFields();
    }
    // 右上角的关闭按钮
    private beforeclose() {
        this.showDialog = false;
        this.closeTodo(1); // 给父组件传值
    }
    private applyForeverChange(val: any) {
        if (val === 1) {
            this.formData.expirationDate = '';
        }
        const formRef = this.$refs.formRef! as ElForm;
        formRef.clearValidate('expirationDate');
    }
    private beforeFileUpload(file: any) {
        return new Promise(async (resolve: any, reject: any) => {
            const fileType = file.type;
            const fileName = file.name;
            // 根据后缀判断文件类型
            let fileSuffix = "";
            // 前缀名不能包括 括号
            let filePrefix = "";
            try {
                const fileArr = fileName.split(".");
                fileSuffix = fileArr[fileArr.length - 1];
                filePrefix = fileArr.slice(0, fileArr.length - 1).join('.');
            } catch (err) {
                fileSuffix = "";
                filePrefix = "";
            }
            if (fileSuffix !== 'mf') {
                this.$message.warning('上传文件格式错误!');
                return reject(false);  // 注意这里需要用reject来返回错误的信息,防止图片自动上传
            }
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                this.$message.warning('上传文件大小不能超过 10MB!');
                return reject(false);
            }
            resolve(file);
        });
    }
    private httpRequestFileBusiness(data: any) {
        const param = new FormData();
        param.append('file', data.file);
        this.$httpService.postData(param, '/apiProxy/api/frontend/file/upload_image', {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        })
        .then((res: any) => {
            this.formData.fileName = data.file.name;
            this.formData.fileUrl = res.path;
            const formRef: any = this.$refs.formRef;
            formRef.validateField('fileUrl');
        });
    }
    // WisIOT平台注册文件获取方法
    private handleRegisterFile(flag: boolean) {
        this.RegisterFileFlag = flag;
    }
}
