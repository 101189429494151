
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { RESOURCE_CONFIG } from '@/utils/global';
import { getDateDiffMonth } from '@/utils/utils';

@Component({
    name: 'TimeSelectItem',
})
export default class TimeSelectItem extends Vue {
    private RESOURCE_CONFIG: any = RESOURCE_CONFIG;
    private itemTab: any = {
        text: '',
    };
    private timeNumberSetList: any = [];
    private timeNumber: number = 0;
    @Prop({
        type: Object,
        default: () => [],
    }) private itemData!: any; // 接收父组件传过来的值
    private created() {
        this.itemTab = Object.assign({}, this.itemData);
        this.timeNumber = this.itemTab.orderDetailTimeNumber;
        const setInfo = localStorage.getItem('set_info_api');
        if (setInfo === null || setInfo === '' || setInfo === undefined) {
            this.getSetInfo();
        } else {
            this.init();
        }
    }
    private getSetInfo() {
        // 得到系统商品相关的配置
        const paramData: any = {};
        paramData.preventRepeat = 'timeSelect';
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/goods/set-info')
        .then((res: any) => {
            localStorage.setItem('set_info_api', JSON.stringify(res));
            this.init();
        });
    }
    private init() {
        if (localStorage.getItem('set_info_api') === '' || localStorage.getItem('set_info_api') === undefined) {
            this.$message.error('数据错误');
            return;
        }
        const res = JSON.parse(localStorage.getItem('set_info_api') + '');
        // 配置数据库的TimeNumber
        this.timeNumberSetList = [];
        let diffMonth = 0;
        if (this.itemData.oldEndTime) {
            diffMonth = getDateDiffMonth(this.itemData.oldEndTime);
        }
        res.timeNumberSetList.filter((item: any) => {
            if (diffMonth <= item.number) {
                item.disabled = false;
            } else {
                item.disabled = true;
            }
            this.timeNumberSetList.push(item);
        });
    }
    private getTimePrice() {
        if (this.itemTab.goodsId === RESOURCE_CONFIG.SERVER_GOODSID) {
            const serverGoods: any = {
                goodsId: RESOURCE_CONFIG.SERVER_GOODSID, // 选中商品传给后端的值
                appName: '云服务器',
                orderDetailNumber: 1, // 选中商品数量(固定不变的)
                orderDetailTimeNumber: this.timeNumber, // 时长
                cpu: this.itemTab.cpu,
                memory: this.itemTab.memory,
                harddisk: this.itemTab.harddisk,
                orderGoodsSceneType: RESOURCE_CONFIG.SERVER_GOODSSCENETYPE,
                dbType: this.itemTab.dbType,
            };
            this.handleSelectTodo(serverGoods);
        } else if (this.itemTab.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID) {
            const oracleGoods = {
                goodsId: RESOURCE_CONFIG.ORACLE_GOODSID, // 选中商品传给后端的值
                appName: 'Oracle',
                orderDetailNumber: this.itemTab.orderDetailNumber, // 选中商品数量(累加)
                orderDetailTimeNumber: this.timeNumber, // 时长
                cpu: this.itemTab.cpu,
                memory: this.itemTab.memory,
                harddisk: this.itemTab.harddisk,
                orderGoodsSceneType: RESOURCE_CONFIG.ORACLE_GOODSSCENETYPE,
            };
            this.handleSelectTodo(oracleGoods);
        }
    }
    @Emit('handleSelect')
    private handleSelectTodo(productItem: any): any {
        return productItem;
    }
}
