
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import ApplicationItem from "../../../resourceManage/buy/component/ApplicationItem.vue";
import { moneyFormat } from '@/utils/utils';
import { RESOURCE_CONFIG, SERVER_CONFIG } from '@/utils/global';
const validateNum = (rule: any, value: any, callback: any) => {
    const regex = new RegExp(/^\d{1,3}$/); // 3位数字
    if (Number(value) === 0 || Number(value) === -0) {	// 未输入价格,或输入价格为0
        callback();
    } else if (!regex.test(value)) {	// 输入格式不正确
        callback(new Error('输入格式不正确'));
    } else if (Number(value) < 0) {	// 输入数量小于0
        callback(new Error('添加数量必须不小于0元'));
    } else {
        callback();
    }
};
@Component({
    name: 'AddDriveDrawer',
    components: {
        ApplicationItem,
    },
})
export default class AddDriveDrawer extends Vue {
    private defaultProductImg: any = require('@/assets/images/detail/moreCloud.png');
    private maxheight: any = window.innerHeight - 165;
    private searchParam: any = {
        pageSize: 500,
        pageNo: 1,
        industry: [],
        vendor: '',
        model: '',
        title: '',
    };
    private props: any = {
        checkStrictly: true,
        // lazy: true,
        // lazyLoad: this.industryLazyLoad,
        children: 'child',
        label: 'name',
        value: 'id',
    };
    private industryData: any = [];
    private industryTree: any = [];
    private formData: any = {
        driveData: [],
    };
    private goodsList: any = [];
    // 所有商品价格
    private allProductTab: any = {
        productNum: 0, // 产品个数
        appNum: 0, // 设备数量
        totalMoney: 0,
    };
    private selectGoodsList: any = [];
    @Prop({
        type: Array,
        default: [],
    }) private itemData!: any; // 接收父组件传过来的值
    @Watch('itemData', {immediate: true, deep: true}) // 监听父组件传过来的值
    private async itemDataFun(value: any) {
        this.selectGoodsList = [].concat(value);
        this.computeAllProduct();
    }
    private created() {
        this.getDriveList();
        // 加载行业
        this.getIndustryTree();
    }
    private getHeight() {
        this.maxheight = window.innerHeight - 165;
    }
    private mounted() {
        this.maxheight = window.innerHeight - 165;
        window.addEventListener('resize', this.getHeight);
    }
    private getIndustryTree() {
        const paramData: any = {};
        paramData.pid = 0;
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/drive/industry')
        .then((res: any) => {
            if (res && res.length > 0) {
                this.industryTree = res;
            } else {
                this.industryTree = [];
            }
        });
    }
    private industryLazyLoad(node: any, resolve: any) {
        const { level } = node;
        const paramData: any = {};
        paramData.pid = node.value ? node.value : 0;
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/drive/industry')
        .then((res: any) => {
            const nodes: any = [];
            if (res && res.length > 0) {
                res.forEach((item: any) => {
                    nodes.push({
                        value: item.id,	// 选项值
                        label: item.name,	// 选项名
                        leaf: level >= 3,	// 是否为末尾
                    });
                });
            }
            resolve(nodes);
        });
    }
    private handleCascadeChange() {
        const sysCascadeRef: any = this.$refs.sysCascade;
        const panelRefs: any = sysCascadeRef.$refs.panel;
        if (sysCascadeRef) {
            const children = sysCascadeRef.getCheckedNodes();
            if (children && children.length > 0 && children[0].level >= 4) {   // 判断有没有下级
                sysCascadeRef.dropDownVisible = false; // 监听值发生变化就关闭它
            } else if (children && children.length > 0 && children[0].children.length === 0) { // 判断子级有没有值，没有就加载
                panelRefs.lazyLoad(panelRefs.getCheckedNodes()[0]);
            }
        }
    }
    private getDriveList() {
        const paramData: any = Object.assign({}, this.searchParam);
        if (paramData.industry.length < 4 && paramData.industry.length > 0) {
            paramData.industry = paramData.industry.toString();
        } else {
            paramData.industry = paramData.industry.toString();
        }
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/goods/goods/drive/page')
        .then((res: any) => {
            if (res && res.total > 0) {
                const list = res.list; // [].concat(res.list).concat(res.list);
                this.formData.driveData = [];
                list.forEach((item: any) => {
                    const filterArr = this.selectGoodsList.filter((el: any) => el.goodsId === item.goodsId);
                    let num = 0;
                    if (filterArr.length > 0) {
                        num = filterArr[0].orderDetailNumber;
                    }
                    item.driveNum = num;
                });
                this.formData.driveData = list;
            } else {
                this.formData.driveData = [];
            }
        });
    }
    private onSearch() {
        this.getDriveList();
    }
    private singleDriveNumRules(row: any) {
        return {
            validator: (rule: any, value: any, callback: any) => validateNum(rule, value, callback),
            trigger: 'change',
        };
    }
    // 订单改价change事件
    private singleDriveNumChange(event: any, row: any, fieldIndex: string) {
        // const value = event.target.value;
        const value = event;
        const formRef: any = this.$refs.formRef;
        let driveNum = 0;
        formRef.validateField(`driveData.${fieldIndex}.driveNum`, (errMsg: string) => {
            if (!value || Number(value) === 0 || Number(value) === -0) {
                driveNum = 0;
            } else {
                driveNum = value;
            }
        });
        this.$nextTick(() => {
            const matchIndex = this.selectGoodsList.findIndex((item: any) => (item.goodsId === row.goodsId));
            if (matchIndex >= 0) {
                if (row.driveNum <= 0) {
                    this.selectGoodsList.splice(matchIndex, 1);
                } else {
                    this.selectGoodsList[matchIndex] = {
                        goodsId: row.goodsId, // 选中商品传给后端的值
                        appName: row.goodsTitle,
                        originalPrice: row.originalPrice,
                        orderDetailNumber: driveNum, // 选中商品数量(累加)
                        orderDetailTimeNumber: 999,
                        orderGoodsSceneType: RESOURCE_CONFIG.DRIVE_GOODSSCENETYPE,
                        dbType: 0,
                    };
                }
            } else {
                this.selectGoodsList.push({
                    goodsId: row.goodsId, // 选中商品传给后端的值
                    appName: row.goodsTitle,
                    originalPrice: row.originalPrice,
                    orderDetailNumber: driveNum, // 选中商品数量(累加)
                    orderDetailTimeNumber: 999,
                    orderGoodsSceneType: RESOURCE_CONFIG.DRIVE_GOODSSCENETYPE,
                    dbType: 0,
                });
            }
            this.computeAllProduct();
        });
    }
    private computeAllProduct() {
        let allProductNum = 0;
        let allAppNum = 0;
        let allTotalMoney = 0;
        this.selectGoodsList.forEach((item: any, index: number) => {
            if (item.orderDetailNumber > 0) {
                if (item.originalPrice > 0) {
                    allProductNum += 1;
                    allAppNum += item.orderDetailNumber;
                    allTotalMoney += item.orderDetailNumber * item.originalPrice;
                }
            }
        });
        this.allProductTab = {
            productNum: allProductNum,
            appNum: allAppNum, // 产品个数
            totalMoney: Number(allTotalMoney).toFixed(2),
        };
    }
    // 给父组件传值
    @Emit('clickConfirmAdd')
    private clickConfirmAddTodo(): any {
        return this.selectGoodsList;
    }
    private confirmAdd() {
        this.selectGoodsList = this.selectGoodsList.filter((item: any) => (item.orderDetailNumber > 0));
        if (this.selectGoodsList.length === 0) {
            this.$message.warning('请先选择需要继续购买的设备驱动');
            return;
        }
        this.clickConfirmAddTodo();
    }
}
