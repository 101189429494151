
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import { assembleOrderItemBuyout, isBuyCompletely } from '@/utils/assemble';

@Component({
    name: "OrderDetail",
    components: {
        ContentTitle,
    },
})
export default class OrderDetail extends Vue {
    private baseUrlIframe = process.env.VUE_APP_URL;
    private baseUrl = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private contentTitle: string = "商品订单详情";
    private orderInfo: any = {};
    private historyTab: any = [];
    private payBtn: boolean = false;
    private invoiceBtn: boolean = false;
    private downloadInvoiceBtn: boolean = false;
    private upProofBtn: boolean = false;
    private ckProofBtn: boolean = false;
    private detailBtn: boolean = false;
    private cancelBtn: boolean = false;
    private evaluateBtn: boolean = false;
    private haveOrderContract: boolean = false;
    @Prop({
        type: Object,
        default: {},
    }) private content!: any; // 接收父组件传过来的值
    @Prop({
        type: Number,
        default: 0,
    }) private buyRole !: number;
    @Prop({
        type: Array,
        default: [],
    }) private bankList!: any;
    @Prop({
        type: Function,
        default: () => '',
    }) private moneyFormat!: any;
    // 给父组件传值
    @Emit('infoReturn')
    private closeTodo(num: number): number {
        return num;
    }
    // 给父组件传值
    // 立即支付
    @Emit('allUploadProof')
    private allUploadProof() {
        return this.orderInfo;
    }
    // 申请开票
    @Emit('askforInvoice')
    private askforInvoice() {
        return this.orderInfo;
    }
    // 立即评价
    @Emit('gotoEvaluate')
    private gotoEvaluate() {
        return this.orderInfo;
    }
    // 取消订单
    @Emit('cancelOrder')
    private cancelOrder() {
        return this.orderInfo;
    }
    // 下载合同
    @Emit('downloadContract')
    private downloadContract() {
        return this.orderInfo;
    }
    // 上传凭证
    @Emit('proofClick')
    private proofClick(status: number) {
        return this.content;
    }
    @Watch('content')
    private contentChange(value: any) {
        console.log('watch');
        this.orderInfo = Object.assign({}, value);
        if (isBuyCompletely(this.orderInfo.orderGoodsDetailList)) {
            // 买断产品和授权用户数 上下显示
            const detailListCopy: any = assembleOrderItemBuyout(this.orderInfo.orderGoodsDetailList);
            this.orderInfo.orderGoodsDetailList = [].concat(detailListCopy);
        }
        this.setOperBtn();
    }
    private created() {
        console.log('created');
        this.orderInfo = Object.assign({}, this.content);
        // this.getOrderDetail();
        this.setOperBtn();
    }
    private getOrderDetail() {
        const formData: any = {
            pageNo: 1,
            pageSize: 1,
            orderId: this.orderInfo.id,
        };
        this.$httpService.postData(formData, '/apiProxy/api/frontend/user/order/data')
            .then((res: any) => {
                this.orderInfo = res.list[0];
            });
    }
    private previewList(imageList: any) {
        return imageList.map((item: any) => `${this.baseUrl}${item.image}`);
    }
    private setOperBtn() {
        this.payBtn = false;
        this.cancelBtn = false;
        this.ckProofBtn = false;
        this.upProofBtn = false;
        this.invoiceBtn = false;
        this.downloadInvoiceBtn = false;
        this.evaluateBtn = false;
        this.haveOrderContract = false;
        const {orderStatus, autoPayTimeOutTime, payStyle, oldOrderId, invoiceStatus, invoiceUrl,
            evaluateStatus, money, payOfflineVoucherList, haveOrderContract} = this.content;
        if (orderStatus === 0) {
            this.cancelBtn = true;  // 取消订单
            if (new Date(autoPayTimeOutTime).getTime() > new Date().getTime()) {    // 支付未超时
                if (payStyle === 1) {   // 线下支付
                    if (payOfflineVoucherList != null && payOfflineVoucherList.length > 0) {
                        // 查看凭证
                        this.ckProofBtn = true;
                    } else {
                        this.upProofBtn = true; // 上传凭证
                    }
                } else {    // 线上支付
                    this.payBtn = true; // 立即支付
                }
            }
        }
        if (orderStatus === 3 && money > 0.001 && invoiceStatus === 0) {    // 订单金额大于0.001；并且是已完成的待开票订单；
            this.invoiceBtn = true; // 申请开票
        }
        // if (payOfflineVoucherList != null && payOfflineVoucherList.length > 0) {
        //     // 查看凭证
        //     this.ckProofBtn = true;
        // }
        if (orderStatus === 3 && evaluateStatus === 0) {
            this.evaluateBtn = true;
        }
        this.haveOrderContract = haveOrderContract;

        if (orderStatus === 3 && invoiceStatus === 1 && invoiceUrl && invoiceUrl !== '') {
            this.downloadInvoiceBtn = true;
        }
    }
    private propGoBlack() {
        this.closeTodo(1);
    }
    private downloadInvoiceHandle() {
        const { invoiceUrl } = this.content;
        if (invoiceUrl && invoiceUrl !== '') {
            const domainUrl = this.baseUrlIframe;
            const completeUrl = `${domainUrl}${invoiceUrl}`;
            window.open(completeUrl);
        } else {
            this.$message.warning('电子发票有误，请联系管理人员~');
        }
    }
}
